import { useAxios } from "./useAxios";
import { useTenant } from "./useTenant";

const axios = useAxios()
const { tenant } = useTenant()
const dashboardAxios = useAxios(process.env.VUE_APP_API_REST_BASE_URL)

export function useAffiliate() {
    const organizationId = localStorage.getItem("organizationId")

    async function getAffiliatesSummary() {
        const response = await axios.get(`/products/${tenant.value?.productId}/affiliates/summary`)

        return response.data
    }

    async function getAffiliateLinks() {
        const response = await dashboardAxios.get(`/organizations/${organizationId}/products/${tenant.value.productId}/pages`)

        return response.data
    }

    async function getAffiliateLinksPayment() {
        const response = await dashboardAxios.get(`/organizations/${organizationId}/products/${tenant.value.productId}/variants`)

        return response.data
    }

    async function getAffiliateReferrals() {
        const response = await dashboardAxios.get(`/organizations/${organizationId}/orders?productId[]=${tenant.value.productId}`)

        return response.data
    }

    return {
        getAffiliatesSummary,
        getAffiliateLinks,
        getAffiliateLinksPayment,
        getAffiliateReferrals
    }
}
