<template>
  <div class="grid grid-cols-12 gap-6" v-if="!!lesson && !!course">
    <img v-if="courseBackgroundImage?.bucketLocation" :src="courseBackgroundImage.bucketLocation" class="object-cover w-full max-w-full h-80 absolute left-0" />
    <div class="col-span-12 xl:col-span-8 mt-[15rem] z-10 xl:pl-10">
      <iframe v-if="lesson.content.type === LessonContentTypeEnum.YOUTUBE" class="w-full h-[330px] md:h-[615px] border-0" :src="lesson.content.url" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <iframe v-if="lesson.content.type === LessonContentTypeEnum.VIMEO" class="w-full h-[330px] md:h-[615px] border-0" :src="lesson.content.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      <iframe v-if="lesson.content.type === LessonContentTypeEnum.PANDA_VIDEO" :id="'panda-' + lesson.id" class="w-full h-[330px] md:h-[615px] border-0" :src="lesson.content.url"></iframe>
      <iframe v-if="lesson.content.type === LessonContentTypeEnum.EPLAY" class="w-full h-[330px] md:h-[615px] border-0" :src="lesson.content.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      <div class="bg-background p-6 rounded-lg">
        <div class="mb-4 flex justify-between items-center">
          <div>
            <p v-if="lessonModule" class="text-zinc-400 text-sm text-xs">{{ lessonModule.title }}</p>
            <h1 class="text-xl font-poppins font-medium">{{ lesson.title }}</h1>
            <!-- <p class="text-zinc-400 text-sm">{{ course }}</p> -->
            <p v-if="lesson.content?.duration" class="flex items-center gap-2 text-sm text-zinc-400">
              <template v-if="lesson.content.duration?.hours">{{ lesson.content.duration.hours }}h </template>
              <template v-if="lesson.content.duration?.minutes">{{ lesson.content.duration.minutes }}m </template>
              <template v-if="lesson.content.duration?.seconds">{{ lesson.content.duration.seconds }}s</template>
            </p>
          </div>
          <div class="flex gap-[2px]">
            <button :disabled="!prevLesson" @click="$router.push({ name: 'lessons.show', params: { lessonId: prevLesson!.id, moduleId: prevLesson!.moduleId, courseId: prevLesson!.courseId  } })" class="bg-muted p-2 rounded-tl-2xl rounded-bl-2xl hover:text-primary transition-color duration-200 disabled:opacity-40">
              <ChevronLeft />
            </button>
            <button :disabled="!nextLesson" @click="$router.push({ name: 'lessons.show', params: { lessonId: nextLesson!.id, moduleId: nextLesson!.moduleId, courseId: nextLesson!.courseId  } })" 
            class="bg-muted p-2 rounded-tr-2xl rounded-br-2xl hover:text-primary transition-color duration-200 disabled:opacity-40">
              <ChevronRight />
            </button>
          </div>
        </div>
        <!-- <hr class="my-2">
        <div>
          <h2 class="text-lg font-medium">Sobre esse curso</h2>
          <p class="text-zinc-400 text-sm">{{ course.description }}</p>
        </div> -->
        <hr class="my-2">
        <!-- <div>
          <h2 class="text-lg font-medium">Pelos números</h2>
          <div class="grid grid-cols-2">
            <p class="col-span-1">Nível de experiência: Todos os níveis</p>
            <p class="col-span-1">Alunos: 34353</p>
            <p class="col-span-1">Idiomas: Português</p>
            <p class="col-span-1">Legendas: Sim</p>
            <p class="col-span-1">Aulas: 539</p>
            <p class="col-span-1">Vídeo: 60 horas no total</p>
          </div>
        </div>
        <hr class="my-2"> -->
        <div class="my-8 bg-background rounded-lg">
          <h2 class="text-lg font-medium">Descrição</h2>
          <p class="text-zinc-400 text-sm">{{ lesson.description }}</p>
        </div>
        <div class="flex flex-col gap-4">
          <div v-for="attachment in lesson.attachments" class="my-4 bg-background rounded-lg">
            <div class="flex items-center gap-2 bg-muted rounded-lg bg-opacity-50">
              <div class="bg-gray-200 bg-opacity-10 h-full p-4 rounded-tl-lg rounded-bl-lg">
                <FileDown />
              </div>
              <div class="flex items-center justify-between w-full p-4">
                <p>{{ attachment.title }}</p>
                <button @click="downloadFile(attachment.bucketLocation, attachment.title)">
                  <Download />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-background p-6 rounded-lg mt-4 mb-4" v-if="course.commentsType !== CourseCommentsTypeEnum.INACTIVE">
        <div class="grid gap-3 mb-4">
          <h5>Adicionar comentário</h5>
          <Textarea placeholder="ex: Tenho uma dúvida..." v-model="createCommentForm.content" />
          <Button type="button" @click="createComment()" :disabled="isCreatingComment || !createCommentForm.content">
            <Loader2 v-if="isCreatingComment" class="w-4 h-4 mr-2 animate-spin" />
            Adicionar
          </Button>
        </div>
        <!-- <hr class="my-2"> -->
        <div class="grid gap-6 py-4" v-if="comments?.data.length">
          <LessonCommentComponent :comment="comment" v-for="comment in comments.data" @edit="
            commentToEdit = comment;
            isCommentDialogOpen = true;
            editCommentForm = { content: comment.content };
          " 
          @delete="
            commentToDeleteId = comment.id;
            isDeleteCommentDialogOpen = true;
          " />
          <PaginationApi :data="comments.pagination" :paginate="getComments" />
        </div>
        <SpinnerLoader v-else-if="!comments" />
      </div>
    </div>
    <div class="col-span-12 xl:col-span-4 sm:p-4 xl:pr-10 z-10 mt-[14rem]">
      <div class="bg-background p-6 rounded-lg w-full inline-block">
        <LessonProgressBar v-if="courseProgressPercentage" :lessonsLength="allLessons.length" :lessonsCompletedLength="lessonsCompleted.length" :percentage="Number(courseProgressPercentage)" />
        <Accordion type="single" collapsible class="" :default-value="String(openModule!.id)">
          <template v-for="module in course.modules" :key="module.id">
            <AccordionItem v-if="!!module.lessons.length" :value="String(module.id)">
              <AccordionTrigger>
                <div class="text-start">
                  <h6>{{ module.title }}</h6>
                  <p class="text-sm text-gray-400">{{ module.description }}</p>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div class="flex flex-col gap-1">
                  <RouterLink :to="{ name: 'lessons.show', params: { lessonId: lesson.id, moduleId: lesson.moduleId, courseId: lesson.courseId } }"  
                  class="flex items-center gap-4 hover:bg-muted transition-background duration-200 p-4 py-6 rounded-sm" 
                  :class="{ 'bg-muted': lesson.id == lessonId }"
                  v-for="lesson in module.lessons" :key="lesson.id">
                    <div @click.prevent>
                      <Checkbox id="terms" :default-checked="!!lesson.progress?.completedAt" @update:checked="(checked) => putLessonProgress(lesson.id, { isCompleted: checked })" />
                    </div>
                    <div>
                      <h3>{{ lesson.title }}</h3>
                      <p v-if="lesson.content?.duration" class="flex items-center gap-2 text-xs m-0">
                        <TvMinimalPlay class="h-4 w-4" />
                        <span class="text-gray-300">
                          <template v-if="lesson.content.duration?.hours">{{ lesson.content.duration.hours }}h </template>
                          <template v-if="lesson.content.duration?.minutes">{{ lesson.content.duration.minutes }}m </template>
                          <template v-if="lesson.content.duration?.seconds">{{ lesson.content.duration.seconds }}s</template>
                        </span>
                      </p>
                    </div>
                  </RouterLink>
                </div>
              </AccordionContent>
            </AccordionItem>
          </template>
        </Accordion>
      </div>
    </div>
    <Dialog :open="isCommentDialogOpen" @update:open="(open) => isCommentDialogOpen = open">
      <DialogContent class="sm:max-w-md">
        <DialogHeader>
          <DialogTitle class="text-[1.1rem]">{{commentToEdit ? 'Editar' : 'Adicionar'}} comentário</DialogTitle>
          <DialogDescription class="">
            Compartilhe sua opinião sobre a aula
          </DialogDescription>
        </DialogHeader>
        <div class="grid gap-3 py-6">
          <Label for="content">Comentário</Label>
          <Textarea
            placeholder="ex: Os detalhes que foram ensinados nesta aula são importantes..."
            class="min-h-32"
            id="content"
            v-model="editCommentForm.content"
          />
        </div>
        <DialogFooter class="sm:justify-start">
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              Cancelar
            </Button>
          </DialogClose>
          <Button type="button" @click="updateComment(commentToEdit!.id)" :disabled="isUpdatingComment || !editCommentForm.content">
            <Loader2 v-if="isUpdatingComment" class="w-4 h-4 mr-2 animate-spin" />
            {{commentToEdit ? 'Atualizar' : 'Adicionar'}}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    <Dialog v-if="commentToDeleteId" :open="isDeleteCommentDialogOpen" @update:open="(open) => isDeleteCommentDialogOpen = open">
      <DialogContent class="sm:max-w-md">
        <DialogHeader>
          <div class="flex gap-2 items-center">
            <AlertTriangle class="h-6 w-6" />
            <DialogTitle class="text-[1.1rem]">Excluir Comentário</DialogTitle>
          </div>
          <DialogDescription class="py-2">
            Essa ação é permanente e não poderá ser desfeita. O comentário será removido imediatamente.
          </DialogDescription>
        </DialogHeader>
        <div class="flex items-center space-x-2">
          
        </div>
        <DialogFooter class="sm:justify-start">
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              Cancelar
            </Button>
          </DialogClose>
          <Button type="button" :disabled="isDeletingComment" @click="deleteComment(commentToDeleteId)">
            <Loader2 v-if="isDeletingComment" class="w-4 h-4 mr-2 animate-spin" />
            Excluir Comentário
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
  <SpinnerLoader v-else />
</template>

<script setup lang="ts">
import Accordion from '@/components/ui/accordion/Accordion.vue';
import AccordionContent from '@/components/ui/accordion/AccordionContent.vue';
import AccordionItem from '@/components/ui/accordion/AccordionItem.vue';
import AccordionTrigger from '@/components/ui/accordion/AccordionTrigger.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Textarea } from '@/components/ui/textarea';
import { useAxios } from '@/composables/useAxios';
import { Course } from '@/interfaces/Course';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { LessonProgress } from '@/interfaces/lesson/LessonProgress';
import { Lesson } from '@/interfaces/lesson/Lessons';
import {
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  Download,
  FileDown,
  Loader2,
  TvMinimalPlay,
} from 'lucide-vue-next'
import { computed, onMounted, ref, watch } from 'vue';
import { Pagination } from '@/interfaces/Pagination';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import { toast } from '@/components/ui/toast';
import { Button } from '@/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { LessonContentTypeEnum } from '@/enums/lesson/LessonContentTypeEnum';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { CourseCommentsTypeEnum } from '@/enums/course/CourseCommentsTypeEnum';
import LessonProgressBar from './components/LessonProgressBar.vue'
import { useFile } from '@/composables/useFile';
import LessonCommentComponent from './components/LessonComment.vue';

const axios = useAxios()

const props = defineProps<{ lessonId: number, moduleId: number, courseId: number }>()
const course = ref<Course>()
const lesson = ref<Lesson>()
const comments = ref<Pagination<LessonComment>>()
const createCommentForm = ref({ content: '' })
const editCommentForm = ref({ content: '' })
const isCreatingComment = ref(false)
const commentToEdit = ref<LessonComment>()
const commentToDeleteId = ref<number>()
const isDeleteCommentDialogOpen = ref(false)
const isDeletingComment = ref(false)
const isCommentDialogOpen = ref(false)
const isUpdatingComment = ref(false)
const openModule = computed(() => course.value?.modules.find((m) => props.moduleId == m.id))
const courseBackgroundImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const allLessons = computed(() => {
  const allLessons = course.value?.modules.flatMap((module) => module.lessons) ?? []

  return allLessons
})
const lessonsCompleted = computed(() => allLessons.value.filter((l) => !!l.progress?.completedAt))
const courseProgressPercentage = computed(() => {
  return ((lessonsCompleted.value.length / allLessons.value.length) * 100).toFixed(2)
})
const lessonModule = computed(() => {
  return course.value?.modules.find((m) => m.id === lesson.value?.moduleId)
})
const nextLesson = computed(() => {
  const index = allLessons.value.findIndex((l) => l.id == props.lessonId)

  if (allLessons.value[index + 1]) {
    return allLessons.value[index + 1]
  }

  return null
})
const prevLesson = computed(() => {
  const index = allLessons.value.findIndex((l) => l.id == props.lessonId)

  if (allLessons.value[index - 1]) {
    return allLessons.value[index - 1]
  }

  return null
})
const { downloadFile } = useFile()

function getCourseById() {
  // isLoadingCourse.value = true

  axios.get(`/courses/${props.courseId}`)
    .then((res) => {
      course.value = res.data.course
      putLessonProgress(props.lessonId, { isCompleted: true })
    })
}

function getLessonById() {
  axios.get(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}`)
    .then((res) => {
      lesson.value =  res.data
    })
    // .finally(() => isLoadingLesson.value = false)
}

async function createLessonProgress(lesson: Lesson, data: { isCompleted: boolean, lastWatchedSeconds?: number }) {
  await axios.post(`/courses/${props.courseId}/modules/${lesson.moduleId}/lessons/${lesson.id}/progress`, data)
}

async function updateLessonProgress(lessonProgress: LessonProgress, data: { lastWatchedSeconds?: number; isCompleted?: boolean }) {
  await axios.patch(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${lessonProgress.lessonId}/progress/${lessonProgress.id}`, data)
}

function putLessonProgress(lessonId: number, data: { isCompleted: boolean, lastWatchedSeconds?: number }) {
  for (const module of course.value?.modules ?? []) {
    const lessonFound = module.lessons.find((l) => l.id == lessonId)
    
    if (lessonFound?.progress) {
      lessonFound.progress.completedAt = data.isCompleted ? new Date() : undefined
    }

    if (lessonFound) {
      if (lessonFound.progress) {
        updateLessonProgress(lessonFound.progress, data)
      } else if(!lessonFound.progress) {
        createLessonProgress(lessonFound, data)
      }
    }
  }
}

function getComments(page = 1) {
  axios.get(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

function createComment() {
  isCreatingComment.value = true

  axios.post(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments`, createCommentForm.value)
    .then(() => {
      toast({
        title: 'Comentário cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      createCommentForm.value = { content: '' }
      getComments()
    })
    .finally(() => isCreatingComment.value = false)
}

function updateComment(commentId: number) {
  isUpdatingComment.value = true

  axios.patch(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments/${commentId}`, editCommentForm.value)
    .then(() => {
      toast({
        title: 'Comentário atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })

      const commentIndex = comments.value?.data.findIndex((comment) => comment.id === commentId)

      if (commentIndex) {
        comments.value!.data[commentIndex].content = editCommentForm.value.content!
      }

      isCommentDialogOpen.value = false
    })
    .finally(() => isUpdatingComment.value = false)
}

function deleteComment(commentId: number) {
  axios.delete(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments/${commentId}`)
    .then(() => {
      toast({
        title: 'Comentário excluído com sucesso',
        class: 'bg-green-500 text-white'
      })
      isDeleteCommentDialogOpen.value = false
      comments.value!.data = comments.value!.data.filter((comment) => comment.id !== commentId)
    })
}

onMounted(() => {
  getCourseById()
  getLessonById()
  getComments()
})

watch(() => props.lessonId, () => {
  lesson.value = undefined
  getCourseById()
  getLessonById()
  getComments()
})
</script>

<style lang="scss">
button.hover\:underline:hover{
  text-decoration-line: unset !important;
}
</style>