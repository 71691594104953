import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

import { Pagination, PaginationList, PaginationPrev, PaginationNext, PaginationListItem, PaginationEllipsis, PaginationLast } from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'
import { computed } from 'vue'

interface IPaginationData {
  total: number
  lastPage: number
  prevPage?: number
  nextPage: number
  perPage: number
  page: number
  from: number
  to: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationApi',
  props: {
    data: {},
    paginate: { type: Function }
  },
  setup(__props: any) {

const props = __props

const displayNextToLast = computed(() => 
  !!(props.data.lastPage - 1) && 
  (props.data.lastPage - 1) !== props.data.nextPage && 
  (props.data.lastPage - 1) !== props.data.page && 
  (props.data.lastPage - 1) !== props.data.prevPage
)

const displayLastPage = computed(() => 
  props.data.lastPage !== props.data.nextPage && 
  props.data.lastPage !== props.data.page
)

return (_ctx: any,_cache: any) => {
  return (_ctx.data && _ctx.data.total)
    ? (_openBlock(), _createBlock(_unref(Pagination), {
        key: 0,
        total: _ctx.data.total,
        "sibling-count": 1,
        "show-edges": "",
        "default-page": _ctx.data.page,
        class: "pt-6"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(PaginationList), { class: "flex items-center gap-1" }, {
            default: _withCtx(({ items }) => [
              _createVNode(_unref(PaginationPrev), {
                class: "w-8 h-8",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paginate(_ctx.data.prevPage!))),
                disabled: !_ctx.data.prevPage
              }, null, 8, ["disabled"]),
              (_ctx.data.prevPage !== 1 && _ctx.data.page !== 1)
                ? (_openBlock(), _createBlock(_unref(PaginationListItem), {
                    key: 0,
                    value: 1,
                    "as-child": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        class: "w-8 h-8 p-0",
                        variant: 'outline',
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.paginate(1)))
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" 1 ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_unref(PaginationListItem), {
                value: _ctx.data.page,
                "as-child": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Button), {
                    class: "w-8 h-8 p-0",
                    variant: 'default'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.page), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              (_ctx.data.nextPage)
                ? (_openBlock(), _createBlock(_unref(PaginationListItem), {
                    key: 1,
                    value: _ctx.data.nextPage,
                    "as-child": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        class: "w-8 h-8 p-0",
                        variant: 'outline',
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.paginate(_ctx.data.nextPage)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.data.nextPage), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : _createCommentVNode("", true),
              (displayLastPage.value)
                ? (_openBlock(), _createBlock(_unref(PaginationEllipsis), { key: 2 }))
                : _createCommentVNode("", true),
              (displayNextToLast.value)
                ? (_openBlock(), _createBlock(_unref(PaginationListItem), {
                    key: 3,
                    value: _ctx.data.lastPage - 1,
                    "as-child": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        class: "w-8 h-8 p-0",
                        variant: 'outline',
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.paginate(_ctx.data.lastPage - 1)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString((_ctx.data.lastPage - 1)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : _createCommentVNode("", true),
              (displayLastPage.value)
                ? (_openBlock(), _createBlock(_unref(PaginationListItem), {
                    key: 4,
                    value: _ctx.data.lastPage,
                    "as-child": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        class: "w-8 h-8 p-0",
                        variant: 'outline',
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.paginate(_ctx.data.lastPage)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.data.lastPage), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : _createCommentVNode("", true),
              _createVNode(_unref(PaginationNext), {
                class: "w-8 h-8",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.paginate(_ctx.data.nextPage!))),
                disabled: !_ctx.data.nextPage
              }, null, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["total", "default-page"]))
    : _createCommentVNode("", true)
}
}

})