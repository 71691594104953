<template>
  <main class="w-full max-w-lg mx-auto" v-if="community">
    <!-- <Form
      v-slot="{ meta, values, validate, resetForm }"
      as="" 
      keep-values 
      :validation-schema="toTypedSchema(formSchema)"
    > -->
      <form @submit.prevent="onSubmit" class="space-y-8">
        <div class="space-y-2 text-center">
          <h1 class="text-[1.1rem] font-bold tracking-tighter text-[#000] dark:text-white">Editar comunidade</h1>
          <p class="text-sm text-[#666] dark:text-[#888]">Atualize os dados e permissões da sua comunidade</p>
        </div>
        
        <div class="space-y-4">
          <!-- Campo Nome da Comunidade -->
          <FormField v-slot="{ componentField }" name="name">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Nome da Comunidade</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Digite o nome da comunidade"
                  v-bind="componentField"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <!-- <div class="flex flex-col space-y-2">
            <FormField v-slot="{ componentField }" name="courseId">
              <Label for="courseId">Curso</Label>
              <Select v-bind="componentField">
                <SelectTrigger id="courseId" aria-label="Selecionar curso">
                  <SelectValue placeholder="Selecionar" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="course in courses" :value="String(course.id)">{{ course.title }}</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormField>
          </div> -->
          <div class="flex flex-col space-y-2">
             <!-- Campo Permitir Reações -->
             <FormField v-slot="{ field }" name="allowReactions">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" :checked="form.values.allowReactions" @update:checked="form.setFieldValue('allowReactions', $event)" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Reações</FormLabel>
                  <FormDescription>
                    Permite que os usuários reajam às postagens nesta comunidade.
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>

            <!-- Campo Permitir Comentários -->
            <FormField v-slot="{ field }" name="allowPosts">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" :checked="form.values.allowPosts" @update:checked="form.setFieldValue('allowPosts', $event)" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Comentários</FormLabel>
                  <FormDescription>
                    Permite que os usuários comentem nas postagens desta comunidade.
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>

            <!-- Campo para Habilitar Chat -->
            <FormField v-slot="{ field }" name="allowChat">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" :checked="form.values.allowChat" @update:checked="form.setFieldValue('allowChat', $event)" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Chat</FormLabel>
                  <FormDescription>
                    Permite que os usuários visualizem e comentem no chat
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>
          </div>

          <!-- Botão de Enviar -->
          <Button 
            :disabled="isCreatingCommunity" 
            type="submit" 
            class="w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
          >
            <Loader2 v-if="isCreatingCommunity" class="w-4 h-4 mr-2 animate-spin" />
            {{ isCreatingCommunity ? 'Salvando...' : 'Salvar' }}
          </Button>
        </div>
      </form>
    <!-- </Form> -->
  </main>
  <SpinnerLoader v-else />
  <Toaster />
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Course } from '@/interfaces/Course'
import { Community } from '@/interfaces/courses/communities/Community'
import { useForm } from 'vee-validate'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'

const community = ref<Community>()
const props = defineProps<{ communityId: number }>()
const isCreatingCommunity = ref(false)
const axios = useAxios()
const courses = ref<Course[]>()

const requiredMessage = {
  required_error: "Este campo é obrigatório",
}

const zodSchema = z.object({
  name: z.string(requiredMessage).min(3, "O nome deve ter pelo menos 3 caracteres"),
  allowReactions: z.boolean(),
  allowPosts: z.boolean(),
  allowChat: z.boolean(),
  courseId: z.string(requiredMessage),
})

const formSchema = toTypedSchema(zodSchema);

type FormSchemaType = z.infer<typeof zodSchema>;

const form = useForm({
  validationSchema: formSchema,
})

const onSubmit = form .handleSubmit((values) => {
  form.validate().then(() => {
    updateCommunity(values as FormSchemaType);
  });
})

function updateCommunity(payload: FormSchemaType) {
  isCreatingCommunity.value = true
  const { courseId, ...data } = payload

  axios.patch(`/courses/${courseId}/communities/${community.value!.id}`, data)
    .then(() => {
      toast({
        title: 'Comunidade editada com sucesso!',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Erro ao editar comunidade',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingCommunity.value = false)
}

function getCourses() {
  axios.get(`/courses?perPage=99999`)
    .then((res) => {
      courses.value = res.data.data
    })
}

function getCommunity() {
  community.value = undefined

  axios.get(`/courses/${props.communityId}/communities/${props.communityId}`)
    .then((res) => {
      community.value = res.data
      form.setValues({
        name: community.value!.name,
        allowReactions: !!community.value!.allowReactions,
        allowPosts: !!community.value!.allowPosts,
        allowChat: !!community.value!.allowChat,
        courseId: String(community.value!.courseId)
      })
    })
}

onMounted(() => {
  getCourses()
  getCommunity()
})
</script>