import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-8" }
const _hoisted_2 = { class: "sm:container mx-auto" }
const _hoisted_3 = { class: "bg-card rounded-xl shadow-lg p-6 mb-8" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_5 = { class: "bg-blue-50 p-6 rounded-xl border border-blue-100" }
const _hoisted_6 = { class: "text-[1.1rem] font-bold text-blue-800" }
const _hoisted_7 = { class: "bg-green-50 p-6 rounded-xl border border-green-100" }
const _hoisted_8 = { class: "text-[1.1rem] font-bold text-green-800" }
const _hoisted_9 = { class: "bg-card rounded-xl shadow-lg p-6 mb-8" }
const _hoisted_10 = { class: "space-y-4 mb-8" }
const _hoisted_11 = { class: "font-medium" }
const _hoisted_12 = { class: "text-sm text-muted-foreground" }
const _hoisted_13 = ["onClick", "title"]
const _hoisted_14 = {
  key: 1,
  class: "flex flex-col items-center gap-2"
}
const _hoisted_15 = { class: "space-y-4" }
const _hoisted_16 = { class: "grid grid-cols-12 flex-row items-start justify-between w-full" }
const _hoisted_17 = { class: "col-span-8" }
const _hoisted_18 = { class: "font-medium" }
const _hoisted_19 = { class: "text-sm text-muted-foreground" }
const _hoisted_20 = { class: "col-span-2" }
const _hoisted_21 = { class: "text-sm text-muted-foreground" }
const _hoisted_22 = { class: "col-span-2" }
const _hoisted_23 = { class: "text-sm text-muted-foreground" }
const _hoisted_24 = ["onClick", "title"]
const _hoisted_25 = {
  key: 1,
  class: "flex flex-col items-center gap-2"
}
const _hoisted_26 = {
  key: 0,
  class: "bg-card rounded-xl shadow-lg p-6"
}

import { ref, onMounted } from 'vue'
import { useAffiliate } from '@/composables/useAffiliate'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '@/components/ui/table'
import { Badge } from '@/components/ui/badge'
import { CopyIcon, Link2Off } from 'lucide-vue-next'
import { toast } from '@/components/ui/toast'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { links } from '@unovis/ts/components/sankey/style'
import { paymentStatusDetails, PaymentStatusEnum } from '@/enums/payment/PaymentStatusEnum'
// import { LineChart } from '@/components/ui/chart-line'

export default /*@__PURE__*/_defineComponent({
  __name: 'AffiliatesView',
  setup(__props) {

const affiliatesSummary = ref<{
  totalClicks: number,
  totalCommissions: number
  totalPagesClicks: number
  totalVariantsClicks: number
}>()
const { getAffiliatesSummary, getAffiliateLinks, getAffiliateLinksPayment, getAffiliateReferrals } = useAffiliate()

// Estado
const affiliateLinks = ref<{ id: number, title: string, url: string }[]>()
const affiliateLinksPayment = ref<{ id: number, title: string, url: string, price: number, priceCommission: number }[]>()

const affiliateReferrals = ref<any[]>()

const getBadgeVariant = (status: string) => {
  switch (status) {
    case 'Aprovado':
      return 'success'
    case 'Pendente':
      return 'warning'
    case 'Rejeitado':
      return 'destructive'
    default:
      return 'secondary'
  }
}

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    // You can add a toast notification here to inform the user that the link was copied
    toast({
      title: 'Link copiado com sucesso!',
      description: 'O link do afiliado copiado para a área de transferência.',
      class: 'bg-green-500 text-white',
      duration: 800
    })
  } catch (err) {
    console.error('Falha ao copiar o link: ', err)
  }
}

onMounted(async () => {
  affiliatesSummary.value = await getAffiliatesSummary()
  affiliateLinks.value = await getAffiliateLinks()
  affiliateLinksPayment.value = await getAffiliateLinksPayment()
  affiliateReferrals.value = await getAffiliateReferrals()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "text-[1.1rem] font-bold mb-8" }, "Gerenciamento de Afiliados", -1)),
      (affiliatesSummary.value && affiliateLinks.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-[1.0rem] font-semibold mb-6" }, "Desempenho Geral", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-sm font-medium text-blue-600 mb-1" }, "Total de Cliques", -1)),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(affiliatesSummary.value?.totalClicks), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-sm font-medium text-green-600 mb-1" }, "Comissões Totais", -1)),
                  _createElementVNode("p", _hoisted_8, "R$ " + _toDisplayString(affiliatesSummary.value?.totalCommissions), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bg-purple-50 p-6 rounded-xl border border-purple-100" }, [
                  _createElementVNode("p", { class: "text-sm font-medium text-purple-600 mb-1" }, "Taxa de Conversão"),
                  _createElementVNode("p", { class: "text-[1.1rem] font-bold text-purple-800" }, "0%")
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-[1.0rem] font-semibold mb-6" }, "Seus Links de Afiliados", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "pb-2" }, "Páginas", -1)),
              _createElementVNode("ul", _hoisted_10, [
                (affiliateLinks.value?.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(affiliateLinks.value, (link) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: link.id,
                        class: "flex items-center justify-between bg-card p-4 rounded-xl border border-zinc-800"
                      }, [
                        _createElementVNode("div", null, [
                          _createElementVNode("p", _hoisted_11, _toDisplayString(link.title), 1),
                          _createElementVNode("p", _hoisted_12, _toDisplayString(link.url), 1)
                        ]),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (copyToClipboard(link.url)),
                          class: "ml-4 p-2 text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md transition-colors duration-200",
                          title: 'Copiar link: ' + link.title
                        }, [
                          _createVNode(_unref(CopyIcon), { class: "w-5 h-5" })
                        ], 8, _hoisted_13)
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-zinc-400 font-medium" }, "Não há links, por enquanto", -1)),
                      _createVNode(_unref(Link2Off), { class: "w-14 h-14" })
                    ]))
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "pb-2" }, "Link de Pagamento", -1)),
              _createElementVNode("ul", _hoisted_15, [
                (affiliateLinksPayment.value?.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(affiliateLinksPayment.value, (link) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: link.id,
                        class: "flex items-center justify-between bg-card p-4 rounded-xl border border-zinc-800"
                      }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("p", _hoisted_18, _toDisplayString(link.title), 1),
                            _createElementVNode("p", _hoisted_19, _toDisplayString(link.url), 1)
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-medium" }, "Valor", -1)),
                            _createElementVNode("p", _hoisted_21, _toDisplayString(link.price?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})), 1)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-medium" }, "Comissão", -1)),
                            _createElementVNode("p", _hoisted_23, _toDisplayString(link.priceCommission?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})), 1)
                          ])
                        ]),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (copyToClipboard(link.url)),
                          class: "ml-4 p-2 text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md transition-colors duration-200",
                          title: 'Copiar link: ' + link.title
                        }, [
                          _createVNode(_unref(CopyIcon), { class: "w-5 h-5" })
                        ], 8, _hoisted_24)
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-zinc-400 font-medium" }, "Não há links, por enquanto", -1)),
                      _createVNode(_unref(Link2Off), { class: "w-14 h-14" })
                    ]))
              ])
            ]),
            (affiliateReferrals.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "text-[1.0rem] font-semibold mb-6" }, "Indicações Recentes", -1)),
                  _createVNode(_unref(Table), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TableHeader), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TableRow), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TableHead), null, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode("Cliente")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(TableHead), null, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("Data")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(TableHead), null, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode("Comissão")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(TableHead), null, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createTextVNode("Status")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(TableBody), null, {
                        default: _withCtx(() => [
                          (affiliateReferrals.value.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(affiliateReferrals.value, (referral, index) => {
                                return (_openBlock(), _createBlock(_unref(TableRow), { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(TableCell), null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(referral.client.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(TableCell), null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(new Date(referral.order.createdAt).toLocaleDateString()), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(TableCell), null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(referral.amount.commission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(TableCell), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Badge), {
                                          class: _normalizeClass(["text-white", _unref(paymentStatusDetails)[(referral.order.statusId as PaymentStatusEnum)].bgClass])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(paymentStatusDetails)[(referral.order.statusId as PaymentStatusEnum)].name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["class"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            : (_openBlock(), _createBlock(_unref(TableRow), { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(TableCell), {
                                    colspan: "5",
                                    class: "text-center font-medium py-8"
                                  }, {
                                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                                      _createTextVNode(" Nenhuma indicação encontrada. ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
    ]),
    _createVNode(Toaster)
  ]))
}
}

})