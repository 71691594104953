import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full lg:grid lg:grid-cols-1 min-h-[100vh] pt-14 sm:pt-0" }
const _hoisted_2 = { class: "flex items-center justify-center py-12" }
const _hoisted_3 = { class: "grid gap-2 text-center" }
const _hoisted_4 = { class: "flex justify-center mb-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "grid gap-4" }
const _hoisted_7 = { class: "grid gap-2" }

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
import { onMounted, ref } from 'vue'
import { Loader2 } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'
import { useTenant } from '@/composables/useTenant'


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordRecoveryView',
  setup(__props) {

const { toast } = useToast()
const email = ref('')
const { sendResetPasswordLink, isAuthLoading } = useAuth()
const { tenantLogo, initializeTenant } = useTenant()
onMounted(() => {
  initializeTenant()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(sendResetPasswordLink)(
        email.value,
        () => _unref(toast)({
          title: 'Email enviado com sucesso',
          class: 'bg-green-500 text-white'
        }),
        () => _unref(toast)({
          title: 'Email inválido!',
          description: 'Este email não está cadastrado na plataforma',
          variant: 'destructive'
        })
      )), ["prevent"])),
          class: "mx-auto grid w-full p-4 sm:p-0 sm:w-[390px] gap-14"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_unref(tenantLogo))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(tenantLogo).bucketLocation,
                    height: "42",
                    width: "42",
                    class: ""
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center gap-3 mx-auto" }, [
              _createElementVNode("h1", { class: "text-3xl font-bold" }, " Recuperar a senha ")
            ], -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-muted-foreground" }, " Digite seu email para enviarmos o link de redefinição de senha ", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(Label), { for: "email" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Email")
                ])),
                _: 1
              }),
              _createVNode(_unref(Input), {
                id: "email",
                type: "email",
                placeholder: "ex: email@exemplo.com",
                required: "",
                modelValue: email.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_unref(Button), {
              type: "submit",
              class: "w-full mt-6 py-6",
              disabled: _unref(isAuthLoading) || !email.value
            }, {
              default: _withCtx(() => [
                (_unref(isAuthLoading))
                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _cache[6] || (_cache[6] = _createTextVNode(" Enviar "))
              ]),
              _: 1
            }, 8, ["disabled"]),
            _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"relative\"><div class=\"absolute inset-0 flex items-center\"><div class=\"w-full border-t border-gray-700\"></div></div><div class=\"relative flex justify-center text-sm\"><span class=\"px-2 bg-zinc-700 text-gray-400 rounded-md\">ou</span></div></div>", 1)),
            _createVNode(_unref(Button), {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('login'))),
              variant: "outline",
              class: "w-full"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Entrar ")
              ])),
              _: 1
            })
          ])
        ], 32)
      ])
    ]),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})