<template>
  <main v-if="tournament" class="w-full max-w-[1700px]">
    <form @submit.prevent="onSubmit" class="space-y-8">
      <div class="space-y-2">
        <h1 class="text-[1.1rem] font-bold tracking-tighter text-[#000] dark:text-white">Editar Torneio</h1>
        <p class="text-sm text-[#666] dark:text-[#888]">Crie um novo torneio para engajar participantes</p>
      </div>
      
      <div class="grid md:grid-cols-12 gap-6">
        <Card class="space-y-4 col-span-6 p-6">
          <FormField v-slot="{ field }" name="name">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Nome do Torneio</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Digite o nome do torneio"
                  v-bind="field"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
  
          <FormField v-slot="{ field }" name="description">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Descrição</FormLabel>
              <FormControl>
                <Textarea
                  :default-value="tournament.description"
                  placeholder="Digite uma breve descrição do torneio"
                  v-bind="field"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <FormField v-slot="{ field }" name="variantId">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Variante de Produto</FormLabel>
              <FormControl>
                <Select :default-value="String(tournament.variantId)" v-bind="field">
                  <SelectTrigger id="variantId" aria-label="Selecionar variante">
                    <SelectValue placeholder="Selecionar" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="variant in variants" :key="variant.id" :value="String(variant.id)">
                      {{ variant.title }}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
  
          <FormField v-slot="{ field }" name="status">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Status do Torneio</FormLabel>
              <FormControl>
                <Select :default-value="tournament.status" v-bind="field">
                  <SelectTrigger id="status" aria-label="Selecionar status">
                    <SelectValue placeholder="Selecionar" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem v-for="status in TournamentStatusEnum" :key="status" :value="status">
                      {{ tournamentStatusLabels[status] }}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
  
          <FormField v-slot="{ field }" name="startAt">
            <FormItem class="flex flex-col">
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Data de Início</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      :class="[
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ]"
                    >
                      {{ formatDate(field.value) }}
                      <CalendarIcon class="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    :selected="field.value"
                    @update:model-value="(date: any) => {
                      const constructedDate = new Date(date.year, date.month - 1, date.day);
                      setFieldValue('startAt', constructedDate);
                    }"
                    :disabled-dates="{ before: new Date() }"
                    initialFocus
                    :locale="'pt-BR'"
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
  
          <FormField v-slot="{ field }" name="endAt">
            <FormItem class="flex flex-col">
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Data de Término</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      :class="[
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ]"
                    >
                      {{ formatDate(field.value) }}
                      <CalendarIcon class="ml-auto h-4 w-4 opacity-50"  />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    :selected="field.value"
                    @update:model-value="(date: any) => {
                      const constructedDate = new Date(date.year, date.month - 1, date.day);
                      setFieldValue('endAt', constructedDate);
                    }"
                    :disabled-dates="{ before: new Date() }"
                    initialFocus
                    :locale="'pt-BR'"
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
  
          <Button
            :disabled="isCreatingTournament"
            type="submit"
            class="w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
          >
            <Loader2 v-if="isCreatingTournament" class="w-4 h-4 mr-2 animate-spin" />
            {{ isCreatingTournament ? 'Salvando...' : 'Salvar' }}
          </Button>
        </Card>

        <!-- Updated Prize Section -->
        <div class="col-span-6 space-y-4">
          <Card class="bg-card">
            <CardHeader>
            </CardHeader>
            <CardContent>
              <Tabs default-value="prizes" class="">
                <TabsList class="grid w-full grid-cols-2">
                  <TabsTrigger value="prizes">
                    Prêmios
                  </TabsTrigger>
                  <TabsTrigger value="participants" @click="!tournamentParticipants ? getTournamentParticipants() : ''">
                    Participantes
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="prizes">
                  <TransitionGroup name="prize-list" tag="ul" class="space-y-2">
                    <li v-for="(prize, index) in tournament.prizes" :key="index" class="flex justify-between items-center p-2 bg-secondary rounded-md">
                      <Trophy class="size-7 stroke-yellow-500" :stroke-width="1.25" />
                      <span>{{ tournamentPrizeTypeEnumLabels[prize.type as TournamentPrizeTypeEnum] }} - {{ prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' }) }} ({{ prize.position }}º lugar)</span>
                      <div class="space-x-1">
                        <Button variant="ghost" size="icon" @click.prevent="prizeToUpdate = prize; isUpdatePrizeDialogOpen = true">
                          <Pencil class="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="icon" @click.prevent="selectedPrizeToDeleteId = prize.id; isDeletePrizeConfirmationDialogOpen = true">
                          <Trash2 class="w-4 h-4" />
                        </Button>
                      </div>
                    </li>
                    <li v-for="(prize, index) in values.prizes" :key="index" class="flex justify-between items-center p-2 bg-secondary rounded-md">
                      <Trophy class="size-7 stroke-yellow-500" :stroke-width="1.25" />
                      <span>{{ tournamentPrizeTypeEnumLabels[prize.type as TournamentPrizeTypeEnum] }} - {{ prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' }) }} ({{ prize.position }}º lugar)</span>
                      <Button variant="ghost" size="icon" @click.prevent="removePrize(index)">
                        <XCircle class="h-4 w-4" />
                      </Button>
                    </li>
                  </TransitionGroup>
                  <Button @click.prevent="openPrizeForm" class="w-full mt-4">
                    <PlusCircle class="w-4 h-4 mr-2" />
                    Adicionar Novo Prêmio
                  </Button>
                </TabsContent>
                <TabsContent value="participants">
                  <Table v-if="tournamentParticipants">
                    <TableHeader>
                      <TableRow>
                        <TableHead>Perfil</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <template v-if="tournamentParticipants.data.length">
                        <TableRow 
                          v-for="(participant, index) in tournamentParticipants.data" 
                          :key="participant.id"
                          class="transition-all hover:bg-gray-700/50"
                        >
                          <TableCell>
                            <div class="flex items-center gap-3">
                              <Avatar>
                                <AvatarImage v-if="participant.profile.avatar" :src="participant.profile.avatar?.bucketLocation" :alt="participant.profile.name" />
                                <AvatarFallback>{{ participant.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
                              </Avatar>
                              <div>
                                <div class="font-semibold">{{ participant.profile.name }}</div>
                                <!-- <div class="text-sm text-gray-400">{{ participant.profile.description }}</div> -->
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </template>
                      <template v-else>
                        <TableRow>
                          <TableCell colspan="5" class="text-center font-medium py-8">
                            Sem participantes.
                          </TableCell>
                        </TableRow>
                      </template>
                    </TableBody>
                  </Table>
                  <SpinnerLoader v-else />
                </TabsContent>
              </Tabs>  
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  </main>
  <SpinnerLoader v-else />

  <!-- Prize Form Dialog -->
  <Dialog :open="isPrizeFormOpen" @update:open="isPrizeFormOpen = $event">
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Adicionar Novo Prêmio</DialogTitle>
        <DialogDescription>Preencha os detalhes do prêmio abaixo.</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="addPrize">
        <div class="grid gap-4 py-4">
          <FormField v-slot="{ field }" name="type">
            <FormItem>
              <FormLabel>Tipo de Prêmio</FormLabel>
              <Select v-model="newPrize.type">
                <SelectTrigger>
                  <SelectValue placeholder="Selecione o tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="type in Object.values(TournamentPrizeTypeEnum)" :key="type" :value="type">
                    {{ tournamentPrizeTypeEnumLabels[type] }}
                  </SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="amount">
            <FormItem>
              <FormLabel>Valor</FormLabel>
              <FormControl>
                <Input type="number" v-model="newPrize.amount" placeholder="Valor do prêmio" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="position">
            <FormItem>
              <FormLabel>Posição</FormLabel>
              <FormControl>
                <Input type="number" v-model="newPrize.position" placeholder="Posição do vencedor" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="description">
            <FormItem>
              <FormLabel>Descrição</FormLabel>
              <FormControl>
                <Textarea v-model="newPrize.description" placeholder="Descrição do prêmio" />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
        <DialogFooter>
          <Button :disabled="!newPrize.type || !newPrize.position || (!newPrize.amount && newPrize.type === TournamentPrizeTypeEnum.PIX)" type="submit">Adicionar Prêmio</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>

  <Dialog v-if="prizeToUpdate" :open="isUpdatePrizeDialogOpen" @update:open="isUpdatePrizeDialogOpen = $event">
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Editar Prêmio</DialogTitle>
        <DialogDescription>Preencha os detalhes do prêmio abaixo.</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="addPrize">
        <div class="grid gap-4 py-4">
          <FormField v-slot="{ field }" name="type">
            <FormItem>
              <FormLabel>Tipo de Prêmio</FormLabel>
              <Select v-model="prizeToUpdate.type">
                <SelectTrigger>
                  <SelectValue placeholder="Selecione o tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="type in Object.values(TournamentPrizeTypeEnum)" :key="type" :value="type">
                    {{ tournamentPrizeTypeEnumLabels[type] }}
                  </SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="amount">
            <FormItem>
              <FormLabel>Valor</FormLabel>
              <FormControl>
                <Input type="number" v-model="prizeToUpdate.amount" placeholder="Valor do prêmio" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="position">
            <FormItem>
              <FormLabel>Posição</FormLabel>
              <FormControl>
                <Input type="number" v-model="prizeToUpdate.position" placeholder="Posição do vencedor" />
              </FormControl>
            </FormItem>
          </FormField>
          <FormField v-slot="{ field }" name="description">
            <FormItem>
              <FormLabel>Descrição</FormLabel>
              <FormControl>
                <Textarea v-model="prizeToUpdate.description" placeholder="Descrição do prêmio" />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
        <DialogFooter>
          <Button :disabled="!prizeToUpdate.type || !prizeToUpdate.position || (!prizeToUpdate.amount && prizeToUpdate.type === TournamentPrizeTypeEnum.PIX)" @click.prevent="updatePrize">Salvar</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>

  <Dialog v-if="selectedPrizeToDeleteId" :open="isDeletePrizeConfirmationDialogOpen" @update:open="(open) => isDeletePrizeConfirmationDialogOpen = open">
    <DialogContent class="sm:max-w-md">
      <DialogHeader>
        <div class="flex gap-2 items-center">
          <AlertTriangle class="h-6 w-6" />
          <DialogTitle class="text-[1.1rem]">Cuidado</DialogTitle>
        </div>
        <DialogDescription class="py-2">
          Deseja mesmo deletar esse prêmio?
        </DialogDescription>
      </DialogHeader>
      <div class="flex items-center space-x-2">
        
      </div>
      <DialogFooter class="sm:justify-start">
        <DialogClose as-child>
          <Button type="button" variant="secondary">
            Cancelar
          </Button>
        </DialogClose>
        <Button type="button" :disabled="isDeletingPrize" @click="deletePrize(selectedPrizeToDeleteId)">
          <Loader2 v-if="isDeletingPrize" class="w-4 h-4 mr-2 animate-spin" />
          Confirmar
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
  <Toaster />
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2, CalendarIcon, PlusCircle, Trophy, XCircle, AlertTriangle, Pencil, Trash2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { TournamentStatusEnum, tournamentStatusLabels } from "@/enums/tournaments/TournamentStatusEnum"
import { format } from 'date-fns'
import { useForm } from 'vee-validate'
import { ptBR } from 'date-fns/locale'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Tournament } from '@/interfaces/tournaments/Tournament'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { TournamentPrizeTypeEnum, tournamentPrizeTypeEnumLabels } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum'
import { AxiosResponse } from 'axios'
import { TournamentPrize } from '@/interfaces/tournaments/TournamentPrize'
import { CalendarDate } from '@internationalized/date'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { TournamentParticipant } from '@/interfaces/tournaments/TournamentParticipant'
import { Pagination } from '@/interfaces/Pagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Variant } from '@/interfaces/products/Variant'
import { useVariant } from '@/composables/useVariant'

function formatDate(date: Date | null) {
  return date ? format(date, 'dd/MM/yyyy', { locale: ptBR }) : 'Selecione uma data'
}

const { getAllVariants } = useVariant()
const variants = ref<Variant[]>()
const props = defineProps<{ tournamentId: number }>()
const isCreatingTournament = ref(false)
const axios = useAxios()
const tournament = ref<Tournament>()
const formSchema = z.object({
  name: z.string({ required_error: "Este campo é obrigatório" }).min(3, "O nome deve ter pelo menos 3 caracteres"),
  description: z.string().optional(),
  variantId: z.any(),
  status: z.nativeEnum(TournamentStatusEnum, { required_error: "Este campo é obrigatório" }),
  startAt: z.date({ required_error: "Este campo é obrigatório" }),
  endAt: z.date({ required_error: "Este campo é obrigatório" }),
  prizes: z.array(
    z.object({
      type: z.enum(Object.values(TournamentPrizeTypeEnum) as [string, ...string[]]),
      amount: z.number().optional(),
      position: z.number().int(),
      description: z.string().optional(),
    })
  ).default([])
}).refine((data) => data.endAt > data.startAt, {
  message: "A data de término deve ser posterior à data de início",
  path: ["endAt"],
});
const isPrizeFormOpen = ref(false)
const newPrize = ref<{ type: string, amount?: number, position?: number, description: string }>({
  type: '',
  amount: undefined,
  position: undefined,
  description: ''
})
const isDeletingPrize = ref(false)
const isDeletePrizeConfirmationDialogOpen = ref(false)
const selectedPrizeToDeleteId = ref<number>()
const { handleSubmit, setFieldValue, values, setValues } = useForm({
  validationSchema: toTypedSchema(formSchema),
})
const isUpdatingPrize = ref(false)
const isUpdatePrizeDialogOpen = ref(false)
const prizeToUpdate = ref<TournamentPrize>()
const tournamentParticipants = ref<Pagination<TournamentParticipant>>()

const onSubmit = handleSubmit((values) => {
  updateTournament(values)
})

function openPrizeForm() {
  isPrizeFormOpen.value = true
}

function addPrize() {
  const updatedPrizes = [
    ...(values.prizes ?? []),
    { ...newPrize.value, position: newPrize.value.position! }
  ].sort((a, b) => a.position - b.position);

  setFieldValue('prizes', updatedPrizes)
  newPrize.value = { type: '', amount: undefined, position: undefined, description: '' }
  isPrizeFormOpen.value = false
}

function removePrize(index: number) {
  setFieldValue('prizes', values.prizes?.filter((_, i) => i !== index) ?? [])
}

function updateTournament(payload: z.infer<typeof formSchema>) {
  isCreatingTournament.value = true
  axios.patch(`/tournaments/${tournament.value!.id}`, {
    ...payload,
    startAt: format(payload.startAt, 'yyyy-MM-dd'),
    endAt: format(payload.endAt, 'yyyy-MM-dd'),
  })
    .then(() => {
      toast({
        title: 'Torneio editado com sucesso!',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Erro ao editar torneio',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingTournament.value = false)
}

function getTournament() {
  axios.get(`/tournaments/${props.tournamentId}`)
    .then((res: AxiosResponse<Tournament>) => {
      setValues({
        name: res.data.name,
        description: res.data.description ?? '',
        status: res.data.status,
        startAt: new Date(res.data.startAt),
        endAt: new Date(res.data.endAt),
        prizes: [],
        variantId: String(res.data.variantId)
      })
      tournament.value = res.data
    })
}

function updatePrize() {
  isUpdatingPrize.value = true

  axios.patch(`/tournaments/${tournament.value!.id}/prizes/${prizeToUpdate.value!.id}`, {
    type: prizeToUpdate.value!.type,
    amount: prizeToUpdate.value!.amount ? prizeToUpdate.value!.amount : null,
    position: prizeToUpdate.value!.position,
    description: prizeToUpdate.value!.description,
  })
    .then(() => {
      toast({
        title: 'Prêmio editado com sucesso',
        class: 'bg-green-500 text-white'
      })
      isUpdatePrizeDialogOpen.value = false
    })
    .finally(() => {
      isUpdatingPrize.value = false
    })
}

function deletePrize(id: number) {
  isDeletingPrize.value = true

  axios.delete(`/tournaments/${tournament.value!.id}/prizes/${id}`)
    .then(() => {
      toast({
        title: 'Torneio excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      tournament.value!.prizes = tournament.value!.prizes.filter(p => p.id !== id)
    })
    .finally(() => {
      isDeletingPrize.value = false
      isDeletePrizeConfirmationDialogOpen.value = false
    })
}

function getTournamentParticipants() {
  axios.get(`/tournaments/${props.tournamentId}/participants`)
    .then((res) => {
      tournamentParticipants.value = res.data
    })
}

onMounted(() => {
  getAllVariants().then(value => {
    variants.value = value
  })
  getTournament()
})
</script>