export enum TournamentStatusEnum {
  OPEN = 'open',
  CLOSED = 'closed',
  FINISHED = 'finished'
}

export const tournamentStatusLabels = {
  [TournamentStatusEnum.OPEN]: 'aberto',
  [TournamentStatusEnum.CLOSED]: 'em breve',
  [TournamentStatusEnum.FINISHED]: 'finalizado',
}