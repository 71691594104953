import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "h-48 bg-gray-400 relative" }
const _hoisted_3 = {
  key: 2,
  for: "banner",
  class: "absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex items-end justify-end gap-4 flex-col" }
const _hoisted_6 = { for: "avatar" }
const _hoisted_7 = {
  key: 0,
  class: "flex gap-2 flex-row flex-wrap mr-3"
}
const _hoisted_8 = { class: "flex gap-2 flex-row flex-wrap mr-3" }
const _hoisted_9 = { class: "pt-16 px-4" }
const _hoisted_10 = { class: "flex justify-between items-start mb-4" }
const _hoisted_11 = { class: "flex items-bottom gap-2" }
const _hoisted_12 = { class: "text-[1.1rem] font-bold" }
const _hoisted_13 = { class: "mb-4 text-sm" }
const _hoisted_14 = { class: "flex items-center gap-8 mb-6" }
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = { class: "font-semibold w-[38px] h-[38px] text-center min-w-full" }
const _hoisted_17 = { class: "flex items-end justify-end gap-4 flex-col" }
const _hoisted_18 = { class: "lg:flex gap-4" }
const _hoisted_19 = { class: "flex flex-col items-start justify-start min-h-[70px] w-[200px] xl:w-[260px] flex-wrap" }
const _hoisted_20 = { class: "flex flex-wrap gap-2" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "flex justify-center items-center" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "font-semibold" }
const _hoisted_25 = { class: "flex flex-col items-start justify-start min-h-[70px] w-[200px] xl:w-[260px] flex-wrap" }
const _hoisted_26 = { class: "flex flex-wrap gap-2" }
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "flex justify-center items-center" }
const _hoisted_29 = ["src"]
const _hoisted_30 = { class: "font-semibold" }
const _hoisted_31 = { class: "space-y-4" }
const _hoisted_32 = { class: "space-y-2" }
const _hoisted_33 = { class: "space-y-2" }
const _hoisted_34 = { class: "space-y-2" }
const _hoisted_35 = { class: "space-y-4" }
const _hoisted_36 = { class: "space-y-2" }
const _hoisted_37 = { class: "space-y-2" }
const _hoisted_38 = { class: "space-y-2" }
const _hoisted_39 = {
  key: 0,
  class: ""
}
const _hoisted_40 = {
  key: 1,
  class: "p-4 space-y-4"
}

import { computed, onMounted, ref } from "vue";
import {
    Card,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
    CardFooter,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogScrollContent,
} from "@/components/ui/dialog";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import {
    Pencil,
    ImageUp,
    Loader2,
    Handshake,
} from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { Profile } from "@/interfaces/profiles/Profile";
import { ProfileMediaTypeEnum } from "@/enums/profiles/ProfileMediaTypeEnum";
import { useAxios } from "@/composables/useAxios";
import { ProfileTopic } from "@/interfaces/profiles/profileTopics/ProfileTopic";
import { Pagination } from "@/interfaces/Pagination";
import ProfileTopicComponent from "./ProfileTopicComponent.vue";
import PaginationApi from "@/components/ui/pagination/PaginationApi.vue";
import { vMaska } from "maska/vue"
import { useAuth } from "@/composables/useAuth";
import PerformanceChart from "./PerformanceChart.vue";
import { achievementCriteriaTypeIcons } from "@/enums/achievements/AchievementCriteriaTypeEnum";
import { useChat } from "@/composables/useChat";
import ProfileBankAccounts from "./ProfileBankAccounts.vue";
import { useTournament } from "@/composables/useTournament";
import { AchievementTypeEnum } from "@/enums/achievements/AchievementTypeEnum";
import { useTenant } from "@/composables/useTenant";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileBox',
  props: {
    user: {}
  },
  emits: ['update'],
  setup(__props: any, { emit: __emit }) {

const { isTradersPlatform } = useTenant()
const { userAuthenticated, changePassword, isChangingPassword } = useAuth()
const emit = __emit
const props = __props;
const { toast } = useToast();
const { hasActiveParticipation } = useTournament()
const axios = useAxios()
const avatar = computed(() =>
    props.user.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const banner = computed(() =>
    props.user.media.find((m) => m.type === ProfileMediaTypeEnum.BANNER)
);
const avatarPreview = ref<string>()
const bannerPreview = ref<string>()
const isEditModalOpen = ref(false);
const isUpdatingProfile = ref(false);
const editForm = ref({
    bank: "",
    agency: "",
    account: "",
    document: "",
    notifications: {
        email: false,
        push: false,
        sms: false,
    },
});
const changePasswordPayload = ref({
    password: '',
    passwordConfirm: '',
    passwordOld: '',
})
const profilePayload = ref<{
    avatar?: File
    banner?: File
    name?: string
    description?: string,
    phoneNumber?: string
}>({
    name: props.user.name,
    description: props.user.description,
    phoneNumber: props.user.phoneNumber,
})
const topics = ref<Pagination<ProfileTopic>>()
const topicPayload = ref<{ content: string }>({ content: '' })
const isCreatingTopic = ref(false)
const { sendMessage, isSendingMessage } = useChat()
const isSendMessageDialogOpen = ref(false)
const message = ref('')
const achievements = computed(() => props.user.achievements.filter(achievement => achievement.type === AchievementTypeEnum.ACHIEVEMENT))
const trophies = computed(() => props.user.achievements.filter(achievement => achievement.type === AchievementTypeEnum.TROPHY))

const openEditModal = () => {
    editForm.value = {
        bank: "",
        agency: "",
        account: "",
        document: "",
        notifications: {
            email: false,
            push: false,
            sms: false,
        },
    };
    isEditModalOpen.value = true;
};

function onAvatarChange(e: Event) {
    const file = (e.target as HTMLInputElement).files![0]

    profilePayload.value.avatar = file

    avatarPreview.value = URL.createObjectURL(file)
}

function onBannerChange(e: Event) {
    const file = (e.target as HTMLInputElement).files![0]

    profilePayload.value.banner = file

    bannerPreview.value = URL.createObjectURL(file)
}

function updateProfile() {
    isUpdatingProfile.value = true

    const formData = new FormData()

    for (const key in profilePayload.value) {
        if (profilePayload.value[key as keyof typeof profilePayload.value]) {
            formData.append(key, profilePayload.value[key as keyof typeof profilePayload.value] as any)
        }
    }

    axios.patch(`/profile`, formData)
        .then(() => {
            toast({
                title: 'Perfil atualizado com sucesso',
                class: 'bg-green-500 text-white'
            })
            emit('update')
            isEditModalOpen.value = false;
        })
        .finally(() => isUpdatingProfile.value = false)
}

function createTopic() {
    isCreatingTopic.value = true

    axios.post(`/profile/${props.user.id}/topics`, topicPayload.value)
        .then((res) => {
            getTopics()
            topicPayload.value = { content: '' }
        })
        .finally(() => isCreatingTopic.value = false)
}

function getTopics(page = 1) {
    axios.get(`/profile/${props.user.id}/topics`, { params: { page } })
        .then((res) => {
            topics.value = res.data
        })
}

onMounted(() => {
    getTopics()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Card), { class: "w-full" }, {
      default: _withCtx(() => [
        _createVNode(_unref(CardContent), { class: "p-0" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_unref(userAuthenticated).userId === _ctx.user.userId)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    class: "hidden",
                    accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif",
                    type: "file",
                    onChange: onAvatarChange,
                    id: "avatar"
                  }, null, 32))
                : _createCommentVNode("", true),
              (_unref(userAuthenticated).userId === _ctx.user.userId)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 1,
                    class: "hidden",
                    accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif",
                    type: "file",
                    onChange: onBannerChange,
                    id: "banner"
                  }, null, 32))
                : _createCommentVNode("", true),
              (_unref(userAuthenticated).userId === _ctx.user.userId)
                ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
                    (
                            !banner.value?.bucketLocation &&
                            !bannerPreview.value
                        )
                      ? (_openBlock(), _createBlock(_unref(ImageUp), {
                          key: 0,
                          class: "w-4 h-4"
                        }))
                      : (_openBlock(), _createBlock(_unref(Pencil), {
                          key: 1,
                          class: "w-4 h-4"
                        }))
                  ]))
                : _createCommentVNode("", true),
              (banner.value || bannerPreview.value)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 3,
                    src: bannerPreview.value ? bannerPreview.value : banner.value!.bucketLocation,
                    alt: "Cover Photo",
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, [
                  _createVNode(_unref(Avatar), {
                    class: "absolute bottom-0 left-4 transform translate-y-1/2 border-4 border-white",
                    size: "lg"
                  }, {
                    default: _withCtx(() => [
                      (avatar.value?.bucketLocation || avatarPreview.value)
                        ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                            key: 0,
                            src: avatarPreview.value ? avatarPreview.value : avatar.value!.bucketLocation
                          }, null, 8, ["src"]))
                        : _createCommentVNode("", true),
                      _createVNode(_unref(AvatarFallback), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.name
                                        .split(" ")
                                        .map((word) => word.charAt(0).toUpperCase())
                                    .join("")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                (_unref(userAuthenticated).userId === _ctx.user.userId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_unref(Button), { onClick: openEditModal }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("Editar perfil")
                        ])),
                        _: 1
                      }),
                      (!!profilePayload.value.avatar || !!profilePayload.value.banner)
                        ? (_openBlock(), _createBlock(_unref(Button), {
                            key: 0,
                            onClick: updateProfile,
                            class: "bg-emerald-500 text-white hover:bg-emerald-600"
                          }, {
                            default: _withCtx(() => [
                              (isUpdatingProfile.value)
                                ? (_openBlock(), _createBlock(_unref(Loader2), {
                                    key: 0,
                                    class: "w-4 h-4 mr-2 animate-spin"
                                  }))
                                : _createCommentVNode("", true),
                              _cache[15] || (_cache[15] = _createTextVNode(" Salvar alterações "))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_unref(TooltipProvider), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass({ 'cursor-not-allowed': !_unref(hasActiveParticipation) })
                            }, [
                              _createElementVNode("div", _hoisted_8, [
                                (_unref(userAuthenticated).userId !== _ctx.user.userId)
                                  ? (_openBlock(), _createBlock(_unref(Button), {
                                      key: 0,
                                      disabled: !_unref(hasActiveParticipation),
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (isSendMessageDialogOpen.value = true))
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("Enviar mensagem")
                                      ])),
                                      _: 1
                                    }, 8, ["disabled"]))
                                  : _createCommentVNode("", true)
                              ])
                            ], 2)
                          ]),
                          _: 1
                        }),
                        (!_unref(hasActiveParticipation))
                          ? (_openBlock(), _createBlock(_unref(TooltipContent), { key: 0 }, {
                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createElementVNode("p", null, "Ação bloqueada! É necessário estar participando de um torneio.", -1)
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.user.name), 1),
                    (_ctx.user.isTrader)
                      ? (_openBlock(), _createBlock(_unref(Handshake), {
                          key: 0,
                          class: "text-green-500 w-7 h-7 relative",
                          title: "Trader Verificado"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.user.description), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold mb-2 text-sm" }, "Publicações", -1)),
                      _createElementVNode("p", _hoisted_16, _toDisplayString(topics.value?.pagination.total), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _cache[19] || (_cache[19] = _createElementVNode("h3", { class: "font-semibold mb-2 text-sm" }, "Troféus", -1)),
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_unref(TooltipProvider), null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(trophies.value, (achievement) => {
                              return (_openBlock(), _createBlock(_unref(Tooltip), {
                                key: achievement.id,
                                "delay-duration": 200
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(TooltipTrigger), null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("img", {
                                        src: achievement.icon,
                                        style: {"height":"7rem"}
                                      }, null, 8, _hoisted_21)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_unref(TooltipContent), null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_22, [
                                        _createElementVNode("img", {
                                          src: achievement.icon,
                                          class: "h-20"
                                        }, null, 8, _hoisted_23)
                                      ]),
                                      _createElementVNode("p", _hoisted_24, _toDisplayString(achievement.title), 1),
                                      _createElementVNode("p", null, _toDisplayString(achievement.description), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _cache[20] || (_cache[20] = _createElementVNode("h3", { class: "font-semibold mb-2 text-sm" }, "Conquistas", -1)),
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_unref(TooltipProvider), null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(achievements.value, (achievement) => {
                              return (_openBlock(), _createBlock(_unref(Tooltip), {
                                key: achievement.id,
                                "delay-duration": 200
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(TooltipTrigger), null, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(Badge), {
                                        variant: "secondary",
                                        class: "p-2"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("img", {
                                            src: achievement.icon,
                                            class: "h-10"
                                          }, null, 8, _hoisted_27)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_unref(TooltipContent), null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_28, [
                                        _createElementVNode("img", {
                                          src: achievement.icon,
                                          class: "h-20 text-center"
                                        }, null, 8, _hoisted_29)
                                      ]),
                                      _createElementVNode("p", _hoisted_30, _toDisplayString(achievement.title), 1),
                                      _createElementVNode("p", null, _toDisplayString(achievement.description), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(Dialog), {
          open: isSendMessageDialogOpen.value,
          "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((isSendMessageDialogOpen).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "w-full max-w-[500px] grid-rows-[auto_minmax(0,1fr)_auto]" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    (_unref(hasActiveParticipation))
                      ? (_openBlock(), _createBlock(_unref(DialogTitle), { key: 0 }, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Enviar mensagem")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Textarea), {
                  id: "message",
                  placeholder: "ex: Olá...",
                  modelValue: message.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((message).value = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_unref(Button), {
                  disabled: _unref(isSendingMessage) || !message.value,
                  onClick: _cache[2] || (_cache[2] = () => _unref(sendMessage)(message.value, _ctx.user.userId, () => {
                        _unref(toast)({
                            title: 'Sucesso',
                            description: 'Mensagem enviada',
                            class: 'bg-green-500 text-white',
                            duration: 1500
                        });
                        isSendMessageDialogOpen.value = false
                        message.value = ''
                    }))
                }, {
                  default: _withCtx(() => [
                    (_unref(isSendingMessage))
                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                          key: 0,
                          class: "w-4 h-4 mr-2 animate-spin"
                        }))
                      : _createCommentVNode("", true),
                    _cache[22] || (_cache[22] = _createTextVNode(" Enviar mensagem "))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]),
        (_unref(userAuthenticated).userId === _ctx.user.userId)
          ? (_openBlock(), _createBlock(_unref(Dialog), {
              key: 0,
              open: isEditModalOpen.value,
              "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((isEditModalOpen).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogScrollContent), { class: "w-full max-w-[800px] grid-rows-[auto_minmax(0,1fr)_auto]" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(DialogTitle), null, {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createTextVNode("Editar perfil")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(DialogDescription), null, {
                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                            _createTextVNode(" Atualize as informações do seu perfil, dados bancários, senha, e preferências de notificação. ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Tabs), {
                      "default-value": "profile",
                      class: "w-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TabsList), { class: "grid w-full grid-cols-3" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TabsTrigger), { value: "profile" }, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createTextVNode("Perfil")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TabsTrigger), { value: "bank" }, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("Dados bancários")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TabsTrigger), { value: "password" }, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createTextVNode("Senha")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(TabsContent), { value: "profile" }, {
                          default: _withCtx(() => [
                            _createElementVNode("form", {
                              onSubmit: _withModifiers(updateProfile, ["prevent"])
                            }, [
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("div", _hoisted_32, [
                                  _createVNode(_unref(Label), { for: "name" }, {
                                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                                      _createTextVNode("Nome")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "name",
                                    modelValue: profilePayload.value.name,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((profilePayload.value.name) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_33, [
                                  _createVNode(_unref(Label), { for: "phoneNumber" }, {
                                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                                      _createTextVNode("Número de telefone")
                                    ])),
                                    _: 1
                                  }),
                                  _withDirectives(_createVNode(_unref(Input), {
                                    type: "tel",
                                    id: "phoneNumber",
                                    modelValue: profilePayload.value.phoneNumber,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((profilePayload.value.phoneNumber) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"]), [
                                    [_unref(vMaska), '(##) # ####-####']
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_34, [
                                  _createVNode(_unref(Label), { for: "description" }, {
                                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                                      _createTextVNode("Descrição")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Textarea), {
                                    id: "description",
                                    modelValue: profilePayload.value.description,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((profilePayload.value.description) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createVNode(_unref(Button), {
                                  onClick: updateProfile,
                                  type: "submit",
                                  disabled: !profilePayload.value.name ||
                                        !profilePayload.value.description ||
                                        (!!profilePayload.value.phoneNumber && profilePayload.value.phoneNumber?.length < 16)
                                }, {
                                  default: _withCtx(() => [
                                    (isUpdatingProfile.value)
                                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                                          key: 0,
                                          class: "w-4 h-4 mr-2 animate-spin"
                                        }))
                                      : _createCommentVNode("", true),
                                    _cache[31] || (_cache[31] = _createTextVNode(" Salvar "))
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ])
                            ], 32)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(TabsContent), { value: "bank" }, {
                          default: _withCtx(() => [
                            _createVNode(ProfileBankAccounts)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(TabsContent), { value: "password" }, {
                          default: _withCtx(() => [
                            _createElementVNode("form", {
                              onSubmit: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
                            }, [
                              _createElementVNode("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createVNode(_unref(Label), { for: "current-password" }, {
                                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                                      _createTextVNode("Senha atual")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "current-password",
                                    type: "password",
                                    modelValue: changePasswordPayload.value.passwordOld,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((changePasswordPayload.value.passwordOld) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_unref(Label), { for: "new-password" }, {
                                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                                        _createTextVNode("Nova senha")
                                      ])),
                                      _: 1
                                    }),
                                    _cache[34] || (_cache[34] = _createElementVNode("p", { class: "text-xs text-zinc-500" }, "Deve conter no mínimo 8 caracteres", -1))
                                  ]),
                                  _createVNode(_unref(Input), {
                                    id: "new-password",
                                    type: "password",
                                    modelValue: changePasswordPayload.value.password,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((changePasswordPayload.value.password) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_38, [
                                  _createVNode(_unref(Label), { for: "confirm-password" }, {
                                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                                      _createTextVNode("Confirmar nova senha")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "confirm-password",
                                    type: "password",
                                    modelValue: changePasswordPayload.value.passwordConfirm,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((changePasswordPayload.value.passwordConfirm) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createVNode(_unref(Button), {
                                  disabled: _unref(isChangingPassword) ||
                                        !changePasswordPayload.value.passwordOld ||
                                        !changePasswordPayload.value.password ||
                                        !changePasswordPayload.value.passwordConfirm ||
                                        changePasswordPayload.value.password !== changePasswordPayload.value.passwordConfirm ||
                                        changePasswordPayload.value.password.length < 8 ||
                                        changePasswordPayload.value.passwordConfirm.length < 8,
                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(changePassword)(
                        changePasswordPayload.value,
                        () => {
                            _unref(toast)({
                                title: 'Senha alterada com sucesso',
                                class: 'bg-green-500 text-white'
                            })
                            changePasswordPayload.value = {
                                passwordOld: '',
                                password: '',
                                passwordConfirm: '',
                            }
                        },
                        () => _unref(toast)({
                            title: 'Senha atual incorreta',
                            description: 'Tente novamente',
                            variant: 'destructive'
                        })
                    ))),
                                  type: "submit"
                                }, {
                                  default: _withCtx(() => [
                                    (_unref(isChangingPassword))
                                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                                          key: 0,
                                          class: "w-4 h-4 mr-2 animate-spin"
                                        }))
                                      : _createCommentVNode("", true),
                                    _cache[36] || (_cache[36] = _createTextVNode(" Salvar "))
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ])
                            ], 32)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["open"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_unref(isTradersPlatform))
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          class: "w-full"
        }, {
          default: _withCtx(() => [
            _createVNode(PerformanceChart, {
              "tournament-daily-scores": _ctx.user.tournamentDailyScores
            }, null, 8, ["tournament-daily-scores"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_unref(Card), { class: "w-full" }, {
      default: _withCtx(() => [
        _createVNode(_unref(Tabs), {
          "default-value": "posts",
          class: "w-full"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TabsContent), { value: "posts" }, {
              default: _withCtx(() => [
                (_unref(userAuthenticated).userId === _ctx.user.userId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createVNode(_unref(CardHeader), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(CardTitle), { class: "text-[1.0rem]" }, {
                            default: _withCtx(() => _cache[37] || (_cache[37] = [
                              _createTextVNode("Criar Publicação")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(CardContent), { class: "grid gap-6" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Textarea), {
                            modelValue: topicPayload.value.content,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((topicPayload.value.content) = $event)),
                            placeholder: "No que você está pensando?"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(CardFooter), { class: "flex justify-between" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Button), {
                            disabled: !topicPayload.value.content || isCreatingTopic.value,
                            onClick: createTopic
                          }, {
                            default: _withCtx(() => [
                              (isCreatingTopic.value)
                                ? (_openBlock(), _createBlock(_unref(Loader2), {
                                    key: 0,
                                    class: "w-4 h-4 mr-2 animate-spin"
                                  }))
                                : _createCommentVNode("", true),
                              _cache[38] || (_cache[38] = _createTextVNode(" Publicar "))
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (!!topics.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topics.value.data, (topic) => {
                        return (_openBlock(), _createBlock(ProfileTopicComponent, {
                          profile: _ctx.user,
                          key: topic.id,
                          topic: topic,
                          onDelete: ($event: any) => (topics.value.data = topics.value.data.filter((t) => t.id !== topic.id))
                        }, null, 8, ["profile", "topic", "onDelete"]))
                      }), 128)),
                      _createVNode(PaginationApi, {
                        data: topics.value.pagination,
                        paginate: getTopics
                      }, null, 8, ["data"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})