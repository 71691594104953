import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-gray-100 p-8 max-w-4xl mx-auto bg-card mt-8 rounded-lg overflow-auto"
}
const _hoisted_2 = { class: "max-w-7xl mx-auto bg-card rounded-lg shadow-xl overflow-hidden" }
const _hoisted_3 = { class: "p-6" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = { class: "flex items-center gap-3" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "text-sm text-gray-400" }
const _hoisted_8 = { class: "flex items-center gap-2" }
const _hoisted_9 = { class: "font-mono" }

import { ref, onMounted } from 'vue'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { ShieldIcon } from 'lucide-vue-next'
import TopThree from './components/TopThree.vue'
import { useRanking } from '@/composables/useRanking'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { TournamentRanking } from '@/interfaces/tournaments/TournamentRanking'
import { useAuth } from '@/composables/useAuth'


export default /*@__PURE__*/_defineComponent({
  __name: 'RankingView',
  props: {
    tournamentId: {}
  },
  setup(__props: any) {

const topThree = ref<TournamentRanking[]>()
const props = __props
const { getRankings, rankings } = useRanking()

const { userAuthenticated } = useAuth()

onMounted(() => {
  getRankings(props.tournamentId)
    .then(() => {
      topThree.value = rankings.value!.data
    })
})

return (_ctx: any,_cache: any) => {
  return (_unref(rankings))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(TopThree, {
          winners: topThree.value ?? []
        }, null, 8, ["winners"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-wrap items-center justify-between gap-4 mb-6" }, null, -1)),
            _createVNode(_unref(Table), null, {
              default: _withCtx(() => [
                _createVNode(_unref(TableHeader), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TableRow), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TableHead), { class: "w-[100px]" }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode("Posição")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(TableHead), null, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("Membro")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(TableHead), null, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Patente")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(TableHead), null, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Pontos")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(TableBody), null, {
                  default: _withCtx(() => [
                    (_unref(rankings) && _unref(rankings).data.length)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(rankings).data, (ranking, index) => {
                          return (_openBlock(), _createBlock(_unref(TableRow), {
                            key: ranking.id,
                            class: _normalizeClass([{
                  'bg-yellow-900/20' : ranking.userId === _unref(userAuthenticated).userId
                }, "transition-all hover:bg-gray-700/50"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TableCell), { class: "font-medium" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([
                        'w-8 h-8 rounded-full flex items-center justify-center text-xs font-bold',
                        index === 0 ? 'bg-yellow-500 text-yellow-900' :
                        index === 1 ? 'bg-gray-300 text-gray-800' :
                        index === 2 ? 'bg-orange-500 text-orange-900' :
                        'bg-gray-700'
                      ])
                                    }, _toDisplayString(index + 1), 3)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_unref(Avatar), null, {
                                      default: _withCtx(() => [
                                        (ranking.profile.avatar)
                                          ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                                              key: 0,
                                              src: ranking.profile.avatar?.bucketLocation,
                                              alt: ranking.profile.name
                                            }, null, 8, ["src", "alt"]))
                                          : _createCommentVNode("", true),
                                        _createVNode(_unref(AvatarFallback), null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(ranking.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_6, _toDisplayString(ranking.profile.name), 1),
                                      _createElementVNode("div", _hoisted_7, _toDisplayString(ranking.profile.description), 1)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_unref(ShieldIcon), {
                                      class: _normalizeClass([
                      'w-5 h-5',
                      ranking.amount < 10 ? 'text-gray-500' :
                      ranking.amount < 20 ? 'text-blue-500' :
                      ranking.amount < 30 ? 'text-purple-500' :
                      'text-yellow-500'
                    ])
                                    }, null, 8, ["class"]),
                                    _createTextVNode(" " + _toDisplayString(ranking.amount), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, _toDisplayString(ranking.amount.toLocaleString()), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        }), 128))
                      : (_openBlock(), _createBlock(_unref(TableRow), { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TableCell), {
                              colspan: "5",
                              class: "text-center font-medium py-8"
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" Nada para mostrar por enquanto, continue acompanhando! ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(PaginationApi, {
          data: _unref(rankings).pagination,
          paginate: (page) => _unref(getRankings)(_ctx.tournamentId, page)
        }, null, 8, ["data", "paginate"])
      ]))
    : _createCommentVNode("", true)
}
}

})