import { ref } from "vue"
import { useAxios } from "./useAxios"
import { AxiosError, AxiosResponse } from "axios"
import { Profile } from "@/interfaces/profiles/Profile"

const isAuthLoading = ref(false)
const isChangingPassword = ref(false)
const axios = useAxios()

export interface RegisterPayload {
    email: string
    password: string
    name: string
    phoneNumber: string
    organizationName: string
    document: string
}

export interface LoginPayload {
    email: string
    password: string
}

const userAuthenticated = ref(JSON.parse(localStorage.getItem('authUser') ?? '{}'))

export function useAuth() {

    async function register(payload: RegisterPayload, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
        isAuthLoading.value = true

        try {
            const res = await axios.post('/accounts', { origin: 'member-area', ...payload }, { baseURL: process.env.VUE_APP_API_REST_BASE_URL })

            onSuccess(res)
        } catch (error) {
            const axiosError = error as AxiosError<any>;
            onError(axiosError.response?.data.errors);
        } finally {
            isAuthLoading.value = false
        }
    }

    function login(payload: LoginPayload, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
        isAuthLoading.value = true

        axios.post('/sessions', payload)
            .then((res) => {
                localStorage.setItem('authToken', res.data.token)
                localStorage.setItem('authUserId', res.data.userId)
                localStorage.setItem('authUser', JSON.stringify(res.data))
                localStorage.setItem('organizationId', JSON.stringify(res.data.organizationIds[0]))

                userAuthenticated.value = res.data

                onSuccess(res)
            })
            .catch((error) => {
                onError(error)
            })
            .finally(() => isAuthLoading.value = false)
    }

    function logout(callback: Function) {
        localStorage.clear()
        callback()
    }

    function sendResetPasswordLink(email: string, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
        isAuthLoading.value = true

        axios.put('/accounts/password/recovery', { email })
            .then((res) => {
                onSuccess(res)
            })
            .catch((error) => {
                onError(error)
            })
            .finally(() => isAuthLoading.value = false)
    }

    function resetPassword(payload: { token: string, password: string }, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
        isAuthLoading.value = true

        axios.patch('/accounts/password/recovery', payload)
            .then((res) => {
                onSuccess(res)
            })
            .catch((error) => {
                onError(error)
            })
            .finally(() => isAuthLoading.value = false)
    }

    async function getProfile(): Promise<Profile> {
        const res = await axios.get(`/profile/${userAuthenticated.value.userId}`)

        return res.data
    }

    async function changePassword(payload: { passwordOld: string, password: string, passwordConfirm: string }, onSuccess: Function, onError: Function): Promise<boolean> {
        isChangingPassword.value = true

        try {
            await axios.put('/accounts', payload)
            onSuccess()
        } catch (e) {
            onError()
        }

        isChangingPassword.value = false

        return true
    }

    function hasRoles(roles: string[]) {
        return roles.some((roleName: string) => {
            return userAuthenticated.value?.roles?.some((role: any) => {
                return role === roleName
            })
        })
    }

    return {
        userAuthenticated: userAuthenticated.value,
        register,
        login,
        logout,
        isAuthLoading,
        sendResetPasswordLink,
        resetPassword,
        getProfile,
        changePassword,
        isChangingPassword,
        hasRoles
    }
}
