<template>
  <div class="bg-background flex flex-col">
    <header class="">
      <div class="container mx-auto px-4 py-6">
        <h1 class="text-[1.1rem] font-bold text-foreground text-center">Perguntas frequentes</h1>
      </div>
    </header>

    <main class="flex-grow container mx-auto px-4 py-8">
      <Card class="max-w-3xl mx-auto">
        <CardHeader>
          <!-- <CardTitle class="text-xl font-semibold">Por favor, leia atentamente nossos termos</CardTitle> -->
        </CardHeader>
        <CardContent>
          <ScrollArea class="h-[60vh] pr-4">
            <div class="space-y-4 text-muted-foreground" v-html="tenant.faq"></div>
          </ScrollArea>
        </CardContent>
      </Card>
    </main>
  </div>
</template>

<script setup lang="ts">
import { Card, CardHeader, CardContent } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useTenant } from '@/composables/useTenant';

const { tenant } = useTenant()
</script>