import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-8" }
const _hoisted_2 = { class: "sm:container mx-auto" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
}
const _hoisted_4 = { class: "flex items-start space-x-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "text-[1.0rem] font-semibold text-white mb-2" }
const _hoisted_8 = { class: "text-gray-400 text-sm text-[.9rem]" }

import { onMounted, ref } from 'vue'
import { useAchievement } from '@/composables/useAchievement'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Achievement } from '@/interfaces/achievements/Achievement';


export default /*@__PURE__*/_defineComponent({
  __name: 'AchievementIndexView',
  setup(__props) {

const achievements = ref<Achievement[]>()

const { getAllAchievements } = useAchievement()

onMounted(() => {
  getAllAchievements()
    .then(data => achievements.value = data)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-[1.1rem] font-bold text-white mb-8" }, "Conquistas", -1)),
      (achievements.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(achievements.value, (achievement) => {
              return (_openBlock(), _createElementBlock("div", {
                key: achievement.id,
                class: "bg-card rounded-lg p-6 transition-transform hover:scale-105"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: achievement.icon,
                    class: "w-[80px]",
                    alt: ""
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h3", _hoisted_7, _toDisplayString(achievement.title), 1),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(achievement.description), 1)
                  ])
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
    ])
  ]))
}
}

})