import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sm:py-4" }
const _hoisted_2 = {
  key: 0,
  class: "relative"
}
const _hoisted_3 = {
  key: 0,
  class: "relative"
}
const _hoisted_4 = { class: "flex flex-col gap-14 sm:gap-0 sm:flex-row sm:space-x-2 md:space-x-4 flex-wrap pb-4" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "relative"
}

import Card from '@/components/ui/card/Card.vue';
import { useAxios } from '@/composables/useAxios';
import { Course } from '@/interfaces/Course';
import CourseArtwork from '@/views/home/components/CourseArtwork.vue';
import { Separator } from 'radix-vue';
import { onMounted, ref } from 'vue';
import { Pagination as IPagination } from '@/interfaces/Pagination'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'MyCoursesView',
  setup(__props) {

const axios = useAxios()
const courses = ref<IPagination<Course>>()

function redirectToCourse(course: Course) {
  axios.get(`/courses/${course.id}/modules/lessons/latest-progress`)
    .then((res) => {
      router.push({ name: 'lessons.show', params: { lessonId: res.data.lesson.id, moduleId: res.data.lesson.moduleId, courseId: course.id } })
    })
}

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, owner: false } })
    .then((res) => {
      courses.value = res.data
    })
}

onMounted(() => {
  getCourses()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Card, { class: "col-span-12 p-6 mx-auto max-w-[1700px]" }, {
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex items-center justify-between" }, [
          _createElementVNode("div", { class: "space-y-1" }, [
            _createElementVNode("h2", { class: "text-[1.1rem] font-semibold tracking-tight" }, " Meus cursos "),
            _createElementVNode("p", { class: "text-sm text-muted-foreground" }, " Explore todos os cursos que você adquiriu e comece sua jornada de aprendizado! ")
          ])
        ], -1)),
        _createVNode(_unref(Separator), { class: "my-4" }),
        (courses.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (courses.value?.data && courses.value?.data.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(courses.value.data, (course) => {
                        return (_openBlock(), _createElementBlock("a", {
                          class: "cursor-pointer",
                          onClick: ($event: any) => (redirectToCourse(course)),
                          key: course.title
                        }, [
                          _createVNode(CourseArtwork, {
                            course: course,
                            class: "w-full sm:w-[250px]",
                            "aspect-ratio": "portrait",
                            width: 250,
                            height: 330
                          }, null, 8, ["course"])
                        ], 8, _hoisted_5))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "flex flex-col gap-14 sm:gap-0 sm:flex-row sm:space-x-2 md:space-x-4 flex-wrap pb-4" }, " Nenhum curso encontrado. ", -1)
                  ])))
            ]))
          : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
        (courses.value && (courses.value?.pagination.total ?? 0) > 1)
          ? (_openBlock(), _createBlock(PaginationApi, {
              key: 2,
              data: courses.value.pagination,
              paginate: (page) => { courses.value = undefined; getCourses(page) }
            }, null, 8, ["data", "paginate"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})