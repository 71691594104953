import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-full overflow-hidden lg:w-[821px] p-4 sm:p-0"
}
const _hoisted_2 = {
  key: 0,
  class: "bg-card mb-10 rounded-lg border"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-6" }
const _hoisted_5 = { class: "text-[1.1rem] font-bold" }
const _hoisted_6 = { class: "flex items-center gap-2 py-2" }
const _hoisted_7 = { class: "flex gap-" }
const _hoisted_8 = { class: "w-full space-y-6" }
const _hoisted_9 = {
  key: 1,
  class: "grid gap-6"
}
const _hoisted_10 = { class: "h-[260px] w-full bg-muted rounded-lg" }
const _hoisted_11 = {
  key: 2,
  className: "rounded-lg p-6 pb-14 mb-8"
}
const _hoisted_12 = { className: "flex items-center justify-center mb-4" }
const _hoisted_13 = { class: "flex gap-2 items-center" }

import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Loader2, Sparkles, SquarePen, Trash2, AlertTriangle } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { Community } from '@/interfaces/courses/communities/Community'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Pagination } from '@/interfaces/Pagination'
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic'
import Topic from '@/views/feed/components/topic/Topic.vue'
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum'
import { useAuth } from '@/composables/useAuth'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import router from '@/router'
import { communities } from '@/states/communities';
import { toast, Toaster } from '@/components/ui/toast'


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowCommunityView',
  props: {
    courseId: {},
    communityId: {}
  },
  setup(__props: any) {

const topicsWrapper = ref<HTMLElement | null>(null)
const props = __props
const axios = useAxios()
const communityBanner = computed(() => community.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const topicPayload = ref({
  content: ''
})
const { userAuthenticated } = useAuth()
const areTopicsLoading = ref(false)
const isCommunityOwner = computed(() => userAuthenticated.organizationIds.includes(community.value?.organizationId))
const community = ref<Community>()
const topics = ref<Pagination<CommunityTopic>>()
const isCreatingTopic = ref(false)
const isDeletingCommunity = ref(false)
const isDeleteCommunityConfirmationDialogOpen = ref(false)

// const friendSuggestions = ref([
//   { id: 1, name: 'Carlos Oliveira', avatar: '/placeholder.svg?height=40&width=40', initials: 'CO' },
//   { id: 2, name: 'Ana Rodrigues', avatar: '/placeholder.svg?height=40&width=40', initials: 'AR' },
// ])

// const upcomingEvents = ref([
//   { id: 1, name: 'Meetup de Desenvolvedores', date: '15 de Maio, 19:00' },
//   { id: 2, name: 'Workshop de Vue.js', date: '22 de Maio, 14:00' },
// ])

function getCommunity() {
  community.value = undefined

  axios.get(`/courses/${props.courseId}/communities/${props.communityId}`)
    .then((res) => {
      community.value = res.data
    })
}

function getCommunityTopics({ hasLoading } = { hasLoading: true }) {
  const page = topics.value?.pagination.nextPage ?? 1;
  
  if (hasLoading) {
    areTopicsLoading.value = true;
  }

  axios.get(`/courses/${props.courseId}/communities/${props.communityId}/topics`, { params: { page } })
    .then((res) => {
      topics.value = { ...res.data, data: topics.value && topics.value?.pagination.nextPage ? [...topics.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => areTopicsLoading.value = false)
}

function createCommunityTopic() {
  isCreatingTopic.value = true

  axios.post(`/courses/communities/topics`, { communityId: props.communityId, ...topicPayload.value })
    .then((res) => {
      getCommunityTopics({ hasLoading: false })
      topicPayload.value = {
        content: ''
      }
    })
    .finally(() => isCreatingTopic.value = false)
}

const handleScroll = () => {
  if ((topicsWrapper.value && (topicsWrapper.value?.getBoundingClientRect().bottom < window.innerHeight)) && 
      (topics.value?.pagination.nextPage) && 
      !areTopicsLoading.value) {
    getCommunityTopics()
  }
}

function deleteCommunity() {
  isDeletingCommunity.value = true

  axios.delete(`/courses/${community.value!.courseId}/communities/${community.value!.id}`)
    .then(() => {
      toast({
        title: 'Comunidade excluída com sucesso',
        class: 'bg-green-500 text-white'
      })
      router.push({ name: 'feed' })
      communities.value = communities.value?.filter((c) => c.id !== community.value!.id)
    })
    .catch(() => isDeletingCommunity.value = false)
    .finally(() => {
      isDeletingCommunity.value = false
      isDeleteCommunityConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getCommunityTopics()
  getCommunity()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

watch(() => props.communityId, () => {
  topics.value = undefined
  getCommunityTopics()
  getCommunity()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (community.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (communityBanner.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: "h-full object-cover w-full",
                  style: {"max-height":"350px"},
                  src: communityBanner.value.bucketLocation,
                  alt: ""
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(community.value.name), 1),
                  _createElementVNode("div", _hoisted_6, [
                    (isCommunityOwner.value)
                      ? (_openBlock(), _createBlock(_component_RouterLink, {
                          key: 0,
                          to: { name: 'courses.communities.edit', params: { communityId: community.value.id } }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(SquarePen), { class: "h-5 w-5 mr-2" })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (isCommunityOwner.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (isDeleteCommunityConfirmationDialogOpen.value = true))
                        }, [
                          _createVNode(_unref(Trash2), { class: "h-5 w-5 mr-2" })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("section", _hoisted_8, [
              _createVNode(_unref(Card), { class: "" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CardHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(CardTitle), { class: "text-[1.0rem]" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Criar Publicação")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(CardContent), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Textarea), {
                        modelValue: topicPayload.value.content,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((topicPayload.value.content) = $event)),
                        placeholder: "No que você está pensando?"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(CardFooter), { class: "flex justify-between" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        disabled: isCreatingTopic.value || !topicPayload.value.content,
                        onClick: createCommunityTopic
                      }, {
                        default: _withCtx(() => [
                          (isCreatingTopic.value)
                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                key: 0,
                                class: "w-4 h-4 mr-2 animate-spin"
                              }))
                            : _createCommentVNode("", true),
                          _cache[5] || (_cache[5] = _createTextVNode(" Publicar "))
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (topics.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "grid gap-6",
                    ref_key: "topicsWrapper",
                    ref: topicsWrapper
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topics.value.data, (topic, index) => {
                      return (_openBlock(), _createBlock(Topic, {
                        key: topic.id,
                        topic: topic,
                        onDelete: ($event: any) => (topics.value.data.splice(index, 1)),
                        community: community.value
                      }, null, 8, ["topic", "onDelete", "community"]))
                    }), 128))
                  ], 512))
                : _createCommentVNode("", true),
              (areTopicsLoading.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4], (i) => {
                      return _createElementVNode("div", _hoisted_10)
                    }), 64))
                  ]))
                : _createCommentVNode("", true),
              (topics.value && !topics.value.data.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_unref(Sparkles), { className: "h-12 w-12 text-yellow-400" })
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("h3", { className: "text-xl font-semibold text-white text-center mb-2" }, "Sem publicações por aqui", -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("p", { className: "text-gray-300 text-center" }, " Esta comunidade está esperando por sua primeira publicação. Por que não fazer um? ", -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_unref(Dialog), {
            open: isDeleteCommunityConfirmationDialogOpen.value,
            "onUpdate:open": _cache[3] || (_cache[3] = (open) => isDeleteCommunityConfirmationDialogOpen.value = open)
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogHeader), null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                        _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Cuidado")
                          ])),
                          _: 1
                        })
                      ]),
                      _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Deseja mesmo excluir essa comunidade? ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                  _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DialogClose), { "as-child": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Button), {
                            type: "button",
                            variant: "secondary"
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Cancelar ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(Button), {
                        type: "button",
                        disabled: isDeletingCommunity.value,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (deleteCommunity()))
                      }, {
                        default: _withCtx(() => [
                          (isDeletingCommunity.value)
                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                key: 0,
                                class: "w-4 h-4 mr-2 animate-spin"
                              }))
                            : _createCommentVNode("", true),
                          _cache[11] || (_cache[11] = _createTextVNode(" Confirmar "))
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["open"])
        ]))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})