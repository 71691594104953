<template>
  <div class="bg-gradient-to-br text-white">
    <main class="sm:container mx-auto px-4 py-8">
      <div class="flex flex-col md:flex-row justify-between items-center mb-8">
        <h2 class="text-[1.1rem] font-semibold tracking-tight mb-4 md:mb-0 bg-clip-text">Torneios disponíveis</h2>
      </div>

      <template v-if="tournaments">
        <TransitionGroup
          v-if="tournaments.data.length"
          name="tournament-list"
          tag="div"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          <div
            v-for="tournament in tournaments.data"
            :key="tournament.id"
            class="bg-card rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-emerald-500/20 hover:scale-105 cursor-pointer"
            @click="openTournamentDetails(tournament)"
          >
            <RouterLink class="flex flex-col justify-between h-full w-full" :to="{ name: 'tournaments.show', params: { tournamentId: tournament.id } }">
              <div class="p-6">
                <div class="flex justify-between items-start mb-4">
                  <h3 class="text-lg font-semibold">{{ tournament.name }}</h3>
                  <span
                    :class="{
                      'bg-gray-500': tournament.status === TournamentStatusEnum.FINISHED,
                      'bg-emerald-500': tournament.status === TournamentStatusEnum.OPEN,
                      'bg-blue-500': tournament.status === TournamentStatusEnum.CLOSED
                    }"
                    class="px-2 py-1 rounded-full text-xs font-semibold"
                  >
                    {{ tournamentStatusLabels[tournament.status] }}
                  </span>
                </div>
                <p class="text-gray-400 mb-4 whitespace-pre-wrap text-sm">{{ tournament.description }}</p>
                <div class="flex items-center justify-between text-sm text-gray-400">
                  <div v-if="tournament.prizes.length" class="flex items-center">
                    <TrophyIcon class="mr-2 h-4 w-4 text-yellow-500" />
                    <template v-if="tournament.prizes[0].type === TournamentPrizeTypeEnum.PIX">{{ tournament.prizes[0].amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' }) }}</template>
                    <template v-else>{{ tournament.prizes[0].description }}</template>
                  </div>
                  <div class="flex items-center">
                    <UsersIcon class="mr-2 h-4 w-4 text-blue-500" />
                    {{ tournament.participantCount }} participantes
                  </div>
                </div>
              </div>
              <div class="bg-zinc-700 px-6 py-4">
                <div class="flex items-center justify-between text-sm">
                  <div class="flex items-center">
                    <CalendarIcon class="mr-2 h-4 w-4 text-emerald-500" />
                    {{ formatDate(new Date(tournament.startAt)) }} - {{ formatDate(new Date(tournament.endAt)) }}
                  </div>
                  <ArrowRightIcon class="h-4 w-4 text-emerald-500" />
                </div>
              </div>
            </RouterLink>
          </div>
        </TransitionGroup>
        <div v-else class="flex flex-col items-center justify-center bg-card rounded-lg p-12 text-center">
          <div class="relative mb-6">
            <TrophyIcon class="w-16 h-16 text-gray-600 animate-pulse" />
            <SwordIcon class="w-8 h-8 text-gray-700 absolute -right-2 -bottom-2 transform rotate-45" />
          </div>
          
          <h2 class="text-xl font-semibold mb-3 bg-gradient-to-r from-gray-200 to-gray-400 bg-clip-text text-transparent">
            Nenhum torneio disponível
          </h2>
          
          <p class="text-gray-400 max-w-md mb-8">
            No momento não há torneios ativos. Novos torneios serão anunciados em breve!
          </p>
        </div>
        <PaginationApi v-if="!!tournaments?.data.length" :data="tournaments.pagination" :paginate="getTournaments" />
      </template>
      <SpinnerLoader v-else />
    </main>

    <!-- <TournamentDetailsModal
      v-if="selectedTournament"
      :tournament="selectedTournament"
      @close="selectedTournament = null"
    /> -->
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { format } from 'date-fns'
import {
  Search as SearchIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
  Trophy as TrophyIcon,
  Users as UsersIcon,
  Calendar as CalendarIcon,
  ArrowRight as ArrowRightIcon,
  ChartLine as ChartLineIcon
} from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios';
import { Tournament } from '@/interfaces/tournaments/Tournament';
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { TournamentStatusEnum, tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum';
import { TournamentPrizeTypeEnum } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import { ptBR } from 'date-fns/locale'

const selectedTournament = ref<Tournament>()
const axios = useAxios()
const tournaments = ref<Pagination<Tournament>>()

const formatDate = (date: Date) => {
  return format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })
}

const openTournamentDetails = (tournament: Tournament) => {
  selectedTournament.value = tournament
}

function getTournaments(page = 1) {
  axios.get('/tournaments', { params: { page } })
    .then((res) => {
      tournaments.value = res.data
    })
}

onMounted(() => {
  getTournaments()
})
</script>

<style scoped>
.tournament-list-enter-active,
.tournament-list-leave-active {
  transition: all 0.5s ease;
}
.tournament-list-enter-from,
.tournament-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>