export enum PaymentStatusEnum {
    Processing = 1,
    Authorized = 2,
    Paid = 3,
    Refunded = 4,
    WaitingPayment = 5,
    PendingRefund = 6,
    Refused = 7,
    Chargedback = 8,
    Analyzing = 9,
    PendingReview = 10,
    CheckoutAbandoned = 11,
    StatusWaiting = 12,
    Recurrency = 13,
    Cancelled = 14,
    Undefined = 15,
    RefundPartial = 16,
    ExpiredPayment = 17,
}

// Define a interface para a estrutura de cada status de pagamento
interface PaymentStatusDetail {
    name: string;
    tag: string;
    bgClass: string; // Classe de background do Tailwind
}

// Define o objeto que mapeia o enum para os detalhes do status de pagamento
export const paymentStatusDetails: {
    [key in PaymentStatusEnum]: PaymentStatusDetail;
} = {
    [PaymentStatusEnum.Processing]: {
        name: 'Em processamento',
        tag: 'processing',
        bgClass: 'bg-gray-500',
    },
    [PaymentStatusEnum.Authorized]: {
        name: 'Autorizado',
        tag: 'authorized',
        bgClass: 'bg-green-500',
    },
    [PaymentStatusEnum.Paid]: {
        name: 'Finalizado',
        tag: 'paid',
        bgClass: 'bg-green-500',
    },
    [PaymentStatusEnum.Refunded]: {
        name: 'Reembolsado',
        tag: 'refunded',
        bgClass: 'bg-blue-400',
    },
    [PaymentStatusEnum.WaitingPayment]: {
        name: 'Aguardando pagamento',
        tag: 'waiting_payment',
        bgClass: 'bg-yellow-500',
    },
    [PaymentStatusEnum.PendingRefund]: {
        name: 'Reembolso pendente',
        tag: 'pending_refund',
        bgClass: 'bg-blue-400',
    },
    [PaymentStatusEnum.Refused]: {
        name: 'Recusado',
        tag: 'refused',
        bgClass: 'bg-red-500',
    },
    [PaymentStatusEnum.Chargedback]: {
        name: 'Chargeback',
        tag: 'chargedback',
        bgClass: 'bg-red-600',
    },
    [PaymentStatusEnum.Analyzing]: {
        name: 'Em análise (reembolso)',
        tag: 'analyzing',
        bgClass: 'bg-blue-400',
    },
    [PaymentStatusEnum.PendingReview]: {
        name: 'Revisão pendente',
        tag: 'pending_review',
        bgClass: 'bg-blue-400',
    },
    [PaymentStatusEnum.CheckoutAbandoned]: {
        name: 'Checkout abandonado',
        tag: 'checkout_abandoned',
        bgClass: 'bg-gray-500',
    },
    [PaymentStatusEnum.StatusWaiting]: {
        name: 'Aguardando atualização',
        tag: 'status_waiting',
        bgClass: 'bg-gray-500',
    },
    [PaymentStatusEnum.Recurrency]: {
        name: 'Recorrência',
        tag: 'recurrency',
        bgClass: 'bg-green-500',
    },
    [PaymentStatusEnum.Cancelled]: {
        name: 'Cancelado',
        tag: 'cancelled',
        bgClass: 'bg-red-500',
    },
    [PaymentStatusEnum.Undefined]: {
        name: 'Aguardando pagamento',
        tag: 'undefined',
        bgClass: 'bg-yellow-500',
    },
    [PaymentStatusEnum.RefundPartial]: {
        name: 'Reembolso Parcial',
        tag: 'refund_partial',
        bgClass: 'bg-blue-400',
    },
    [PaymentStatusEnum.ExpiredPayment]: {
        name: 'Boleto Vencido',
        tag: 'expired_payment',
        bgClass: 'bg-red-600',
    },
};
