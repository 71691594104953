import { Tenant } from "@/interfaces/tenants/Tenant"
import { useAxios } from "./useAxios"
import { computed, ref } from "vue"
import { TenantMediaTypeEnum } from "@/enums/tenants/media/TenantMediaTypeEnum"
import { defaultMenuIcons, tradersPlatformMenuIcons } from "@/enums/tenants/icons/TenantIcons"

const axios = useAxios()
const tenantCacheJson = localStorage.getItem('tenant')
const tenant = ref<Tenant>(tenantCacheJson ? JSON.parse(tenantCacheJson) : undefined)
const tenantLogo = computed(() => tenant.value?.media.find(m => m.type === TenantMediaTypeEnum.LOGO))
const tradersPlatformTenantId = process.env.VUE_APP_TRADERS_PLATFORM_TENANT_ID
const isTradersPlatform = computed(() => {
  return tenant.value?.id == tradersPlatformTenantId
})

export function useTenant() {
  async function initializeTenant(): Promise<void> {
    if (!tenant.value) {
      const response = await axios.get('/tenant')
  
      localStorage.setItem('tenant', JSON.stringify(response.data))
  
      tenant.value = response.data
    }

    const favicon = tenant.value?.media.find(m => m.type === TenantMediaTypeEnum.FAVICON)

    if (favicon) {
      const faviconLink = document.createElement('link')
      faviconLink.href = favicon.bucketLocation
      faviconLink.rel = 'icon'

      document.head.appendChild(faviconLink)
    }

    document.head.title = `Área de Membros - ${tenant.value.name}`
  }

  const hasHome = computed(() => isTradersPlatform.value)
  const hasCourses = computed(() => !isTradersPlatform.value);
  const hasChat = computed(() => isTradersPlatform.value);
  const hasAffiliates = computed(() => isTradersPlatform.value);
  const hasTraders = computed(() => isTradersPlatform.value);
  const hasTournaments = computed(() => isTradersPlatform.value);
  const hasAchievements = computed(() => isTradersPlatform.value);

  const menuIcons = computed(() => isTradersPlatform ? tradersPlatformMenuIcons : defaultMenuIcons)

  return {
    initializeTenant,
    tenant,
    tenantLogo,
    menuIcons,
    hasHome,
    hasCourses,
    hasChat,
    hasAffiliates,
    hasTraders,
    hasTournaments,
    hasAchievements,
    isTradersPlatform
  }
}