import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "flex items-center justify-center p-4 relative pb-0 bg-no-repeat bg-center bg-contain",
  style: {"background-image":"url('https://cdn.member-area.hoopay.com.br/traders-cup-brasil/fog-2646.png')"}
}
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = { class: "flex justify-center items-end mx-auto gap-[16px]" }
const _hoisted_4 = {
  style: {"background-image":"url('/assets/img/ranking/podium.png')"},
  class: "bg-contain bg-no-repeat bg-center h-[310px] w-[200px] relative transition-transform hover:scale-110"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  style: {"background-image":"url('/assets/img/ranking/podium.png')"},
  class: "bg-contain bg-no-repeat bg-center h-[360px] w-[230px] relative transition-transform hover:scale-110"
}
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  style: {"background-image":"url('/assets/img/ranking/podium.png')"},
  class: "bg-contain bg-no-repeat bg-center h-[310px] w-[200px] relative transition-transform hover:scale-110"
}
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "mt-8 text-center" }
const _hoisted_11 = { class: "inline-block animate-pulse" }

import { ref } from 'vue'
import { StarIcon } from 'lucide-vue-next'
import { TournamentRanking } from '@/interfaces/tournaments/TournamentRanking';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopThree',
  props: {
    winners: {}
  },
  setup(__props: any) {



const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-3xl font-bold text-center text-white mb-8" }, "Top 3 Traders", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute bottom-[12px] left-1/2 -translate-x-1/2 z-10 w-10 h-10 rounded-full flex items-center justify-center" }, [
            _createElementVNode("span", { class: "text-white font-bold text-xl" }, "2")
          ], -1)),
          (_ctx.winners[1])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.winners[1].profile.avatar?.bucketLocation,
                alt: _ctx.winners[1].profile.name,
                class: "w-[95px] h-[95px] rounded-full object-cover bg-no-repeat absolute left-1/2 -translate-x-1/2 top-[90px]"
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute bottom-[16px] left-1/2 -translate-x-1/2 z-10 w-10 h-10 rounded-full flex items-center justify-center" }, [
            _createElementVNode("span", { class: "text-white font-bold text-xl" }, "1")
          ], -1)),
          (_ctx.winners[0])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.winners[0].profile.avatar?.bucketLocation,
                alt: _ctx.winners[0].profile.name,
                class: "w-[115px] h-[115px] rounded-full object-cover bg-no-repeat absolute left-1/2 -translate-x-1/2 top-[110px]"
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute bottom-[10px] left-1/2 -translate-x-1/2 z-10 w-10 h-10 rounded-full flex items-center justify-center" }, [
            _createElementVNode("span", { class: "text-white font-bold text-xl" }, "3")
          ], -1)),
          (_ctx.winners[2])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.winners[2].profile.avatar?.bucketLocation,
                alt: _ctx.winners[2].profile.name,
                class: "w-[95px] h-[95px] rounded-full object-cover absolute left-1/2 -translate-x-1/2 top-[90px]"
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, [
          _createVNode(_unref(StarIcon), { class: "h-8 w-8 text-yellow-400" })
        ])
      ])
    ])
  ]))
}
}

})