<template>
  <div class="flex min-h-screen w-full flex-col bg-background">
    <aside class="fixed top-10 left-8 mt-7 h-full flex-col pt-4 hidden md:flex w-30 text-center z-10">
      <nav class="grid gap-6 p-2 pt-0 rounded-lg bg-background">
        <!-- Regular navigation items -->
        <TooltipProvider v-if="hasHome">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'home' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Meus cursos"
                >
                  <component :is="menuIcons.home" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Home
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasCourses">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'courses.my' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Meus cursos"
                >
                <component :is="menuIcons.courses" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Meus cursos
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'feed' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Comunidade"
                >
                  <component :is="menuIcons.feed" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Comunidade
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasChat">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'chat' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Chat"
                >
                <component :is="menuIcons.chat" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Chat
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasAffiliates && hasRoles([AclEnum.AFFILIATE])">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'affiliates' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Afiliados"
                >
                <component :is="menuIcons.affiliates" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Afiliados
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasTraders">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'traders' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Traders"
                >
                  <component :is="menuIcons.traders" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Traders
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasTournaments">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'tournaments' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Torneios"
                >
                  <component :is="menuIcons.tournaments" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Torneios
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider v-if="hasAchievements">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'achievements' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Conquistas"
                >
                  <component :is="menuIcons.achievements" class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Conquistas
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <!-- Separator for management links -->
        <div v-if="hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN]) && tenant?.id === 1 || hasRoles([AclEnum.ADMIN_MEMBER_AREA])" class="w-full h-px bg-gray-200 dark:bg-gray-700"></div>

        <!-- Management links -->
        <template v-if="hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN]) && tenant?.id === 1 || hasRoles([AclEnum.ADMIN_MEMBER_AREA])">
          <TooltipProvider>
            <Tooltip :delay-duration="10">
              <TooltipTrigger as-child>
                <RouterLink :to="{ name: 'courses.manage' }" class="rounded-lg" exact-active-class="bg-muted">
                  <Button
                    variant="ghost"
                    size="icon"
                    class="rounded-lg"
                    aria-label="Gerenciar Cursos"
                  >
                    <component :is="menuIcons.manageCourses" class="size-7" :stroke-width="1.25" />
                  </Button>
                </RouterLink>
              </TooltipTrigger>
              <TooltipContent side="right" :side-offset="5">
                Gerenciar Cursos
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider v-if="hasTournaments">
            <Tooltip :delay-duration="100">
              <TooltipTrigger as-child>
                <RouterLink :to="{ name: 'tournaments.manage' }" class="rounded-lg" exact-active-class="bg-muted">
                  <Button
                    variant="ghost"
                    size="icon"
                    class="rounded-lg"
                    aria-label="Gerenciar Torneios"
                  >
                    <component :is="menuIcons.manageTournaments" class="size-7" :stroke-width="1.25" />
                  </Button>
                </RouterLink>
              </TooltipTrigger>
              <TooltipContent side="right" :side-offset="5">
                Gerenciar Torneios
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </template>
      </nav>
    </aside>

    <header class="bg-background sticky top-0 flex h-16 items-center gap-4 border-b mb-3 px-4 md:px-7 z-50">
      <nav class="fixed ml-7 hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6 left-5">
        <a
          href="/#"
          class="flex items-center gap-2 text-lg font-semibold md:text-base"
        >
          <img v-if="tenantLogo" :src="tenantLogo.bucketLocation" style="max-height: 50px; height: 100%;" />
        </a>
        <a
          href="/#"
          class="text-foreground transition-colors hover:text-foreground"
        >

        </a>
      </nav>
      <Sheet>
        <SheetTrigger as-child>
          <Button
            variant="outline"
            size="icon"
            class="shrink-0 md:hidden"
          >
            <Menu class="h-5 w-5" />
            <span class="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav class="grid gap-4 text-lg font-medium">
            <a
              href="#"
              class="flex items-center gap-2 text-lg font-semibold px-3"
            >
              <img v-if="tenantLogo" :src="tenantLogo.bucketLocation" style="max-height: 50px; height: 100%;" />
            </a>
            <!-- Meus Cursos -->
            <RouterLink v-if="hasHome" :to="{ name: 'home' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.home" class="size-5" :stroke-width="1.25" />
              Home
            </RouterLink>
            <!-- Meus Cursos -->
            <RouterLink v-if="hasCourses" :to="{ name: 'courses.my' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.courses" class="size-5" :stroke-width="1.25" />
              Meus cursos
            </RouterLink>
            <!-- Comunidade -->
            <RouterLink :to="{ name: 'feed' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.feed" class="size-5" :stroke-width="1.25" />
              Comunidade
            </RouterLink>
            <!-- Chat -->
            <RouterLink v-if="hasChat" :to="{ name: 'chat' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.chat" class="size-5" :stroke-width="1.25" />
              Chat
            </RouterLink>
            <!-- Afiliados -->
            <RouterLink v-if="hasAffiliates && hasRoles([AclEnum.AFFILIATE])" :to="{ name: 'affiliates' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.affiliates" class="size-5" :stroke-width="1.25" />
              Afiliados
            </RouterLink>
            <!-- Traders -->
            <RouterLink v-if="hasTraders" :to="{ name: 'traders' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.traders" class="size-5" :stroke-width="1.25" />
              Traders
            </RouterLink>
            <!-- Torneios -->
            <RouterLink v-if="hasTournaments" :to="{ name: 'tournaments' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.tournaments" class="size-5" :stroke-width="1.25" />
              Torneios
            </RouterLink>
            <!-- Conquistas -->
            <RouterLink v-if="hasAchievements" :to="{ name: 'achievements' }" class="hover:text-foreground px-3 py-2 rounded-lg flex items-center gap-2" exact-active-class="bg-muted">
              <component :is="menuIcons.achievements" class="size-5" :stroke-width="1.25" />
              Conquistas
            </RouterLink>
          </nav>
        </SheetContent>
      </Sheet>
      <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form class="ml-auto flex-1 sm:flex-initial"></form>
        <DropdownMenu v-model:open="isNotificationDropdownOpen" @update:open="isNotificationDropdownOpen = $event">
          <DropdownMenuTrigger as-child>
            <Button variant="secondary" size="icon" class="rounded-full relative">
              <Bell class="h-5 w-5" />
              <span v-if="unreadCount && unreadCount > 0" class="absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-500 text-xs text-white flex items-center justify-center">
                {{ unreadCount }}
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-80" align="end">
            <NotificationCard @show-more="() => { $router.push({ name: 'notifications' }); isNotificationDropdownOpen = false }" :has-scroll-area="true" />
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="secondary" size="icon" class="rounded-full">
              <CircleUser class="h-5 w-5" />
              <span class="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem @click="$router.push({ name: 'profile', params: { profileId: userAuthenticated.userId }  })">
              <UserCog class="h-4 w-4 mr-2" />
              Minha Conta</DropdownMenuItem>
            <DropdownMenuSeparator />
            <!-- <DropdownMenuItem>
              <HelpCircle class="h-4 w-4 mr-2" /> Suporte
            </DropdownMenuItem> -->
            <DropdownMenuItem @click="$router.push({ name: 'terms-of-use' })">
              <HelpCircle class="h-4 w-4 mr-2" /> Regras e Contrato
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
            class="cursor-pointer"
            @click="logout(
              () => $router.push({ name: 'login' })
            )">
              <LogOut class="h-4 w-4 mr-2" /> Sair
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <!-- <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="outline">
              <Icon icon="radix-icons:moon" class="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Icon icon="radix-icons:sun" class="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
              <span class="sr-only">Mudar tema</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem @click="mode = 'light'">
              Claro
            </DropdownMenuItem>
            <DropdownMenuItem @click="mode = 'dark'">
              Escuro
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> -->
      </div>
    </header>

    <div class="md:ml-[100px] flex justify-center sm:px-7 flex items-center flex-col">
      <RouterView class="w-full" />
      <p v-if="isTradersPlatform" class="text-center text-xs pb-10 pt-4 sm:pt-0">© 2024 Traders Cup Brasil 29.435.748/0001-07. Todos os direitos reservados.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Package2,
  Users,
  CircleUser,
  Menu,
  LogOut,
  HelpCircle,
  Book,
  LibraryBig,
  Trophy,
  UserCog,
  MessageSquare,
  Waypoints,
  Handshake,
  Bell,
  Medal,
  Home,
  CheckCircle
} from 'lucide-vue-next'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { AclEnum } from '@/enums/acl/AclEnum'
import { Button } from '@/components/ui/button'

import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import Tooltip from '@/components/ui/tooltip/Tooltip.vue'
import TooltipTrigger from '@/components/ui/tooltip/TooltipTrigger.vue'
import TooltipContent from '@/components/ui/tooltip/TooltipContent.vue'
import { useAuth } from '@/composables/useAuth'
import { computed, onMounted, ref } from 'vue'
import { useTenant } from '@/composables/useTenant'
import { useNotification } from '@/composables/useNotification'
import NotificationCard from '@/views/notifications/NotificationCard.vue'
import { NotificationStatusEnum } from '@/enums/notifications/NotificationStatusEnum'
import { useTournament } from '@/composables/useTournament'

const isNotificationDropdownOpen = ref(false)
const {
  tenantLogo,
  tenant,
  initializeTenant,
  menuIcons,
  hasHome,
  hasCourses,
  hasChat,
  hasAffiliates,
  hasTraders,
  hasTournaments,
  hasAchievements,
  isTradersPlatform
} = useTenant()
const { checkActiveParticipation, hasActiveParticipation } = useTournament()

const unreadCount = computed(() => notifications.value?.data.filter(n => n.status === NotificationStatusEnum.UNREAD).length)

const { logout, userAuthenticated, hasRoles } = useAuth()
const { notifications, initializeNotifications, readAllNotifications } = useNotification()

onMounted(() => {
  checkActiveParticipation()
  initializeTenant()
  initializeNotifications()
})
</script>
