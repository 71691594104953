<template>
  <div class="sm:container w-full mx-auto sm:p-4 space-y-6 max-w-full">
    <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6" v-if="user">
      <div class="md:col-span-2">
        <ProfileBox @update="getProfile" :user="user" />
      </div>
      <div class="space-y-9">
        <CommentsSection :profile="user" />
      </div>
    </div>
    <SpinnerLoader v-else />
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Toaster } from '@/components/ui/toast'
import ProfileBox from './components/ProfileBox.vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import CommentsSection from './components/commentsSection/CommentsSection.vue'
import { Profile } from '@/interfaces/profiles/Profile'

const props = defineProps<{ profileId: number }>()
const axios = useAxios()
const user = ref<Profile>()

function getProfile() {
  axios.get(`/profile/${props.profileId}`)
    .then((res) => {
      user.value = { ...res.data, phoneNumber: res.data.phoneNumber?.slice(2,) }
    })
}

onMounted(async () => {
  getProfile()
})
</script>
