import { useAxios } from "./useAxios"
import { useTenant } from "./useTenant"

const { tenant } = useTenant()
const apiRestAxios = useAxios(process.env.VUE_APP_API_REST_BASE_URL)

export function useVariant() {
  async function getAllVariants() {
    const response = await apiRestAxios.get(`/organizations/${tenant.value?.organizationId}/products/${tenant.value?.productId}/variants`)

    return response.data
  }

  return {
    getAllVariants
  }
}