<template>
  <div class="text-gray-100 p-4 sm:p-8">
    <div class="sm:container mx-auto">
      <h2 class="text-[1.1rem] font-semibold tracking-tight mb-8">Top Traders Brasil</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <template v-if="traders">
          <template v-if="traders.data.length">
            <div v-for="trader in traders.data" :key="trader.id" class="bg-card rounded-lg shadow-lg overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105" @click="openProfile(trader.id)">
              <img v-if="trader.avatar" :src="trader.avatar?.bucketLocation" :alt="trader.name" class="w-full h-48 object-cover">
              <div class="w-full h-48 bg-zinc-700" v-else>
                {{ trader.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}
              </div>
              <div class="p-4">
                <h2 class="text-xl font-semibold mb-2">{{ trader.name }}</h2>
                <p class="text-gray-400 text-[0.85rem] mb-4">{{ trader.description }}</p>
                <!-- <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-2">
                    <ThumbsUpIcon class="w-5 h-5 text-green-500" />
                    <span>{{ trader. }}</span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <ThumbsDownIcon class="w-5 h-5 text-red-500" />
                    <span>{{ trader.unlikes }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </template>
          <div v-else class="col-span-12 flex flex-col items-center justify-center bg-card rounded-lg p-12 text-center">
            <div class="relative mb-6">
              <UserIcon class="w-16 h-16 text-gray-600 animate-pulse" />
              <ChartLineIcon class="w-8 h-8 text-gray-700 absolute -right-2 -bottom-2" />
            </div>
            
            <h2 class="text-xl font-semibold mb-3 bg-gradient-to-r bg-clip-text">
              Nenhum trader disponível no momento
            </h2>
            
            <p class="text-gray-400 max-w-md mb-8">
              Estamos trabalhando para trazer novos traders para a plataforma. Fique atento para atualizações!
            </p>
          </div>
        </template>
        <div class="flex justify-center w-full col-span-4" v-else>
          <SpinnerLoader />
        </div>
      </div>
      <PaginationApi v-if="!!traders?.data.length" :data="traders.pagination" :paginate="async (page) => traders = await getTraders(page)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useProfile } from '@/composables/useProfile';
import { Profile } from '@/interfaces/profiles/Profile';
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import { ChartLineIcon, UserIcon } from 'lucide-vue-next';

const router = useRouter()
const { getTraders } = useProfile()
const traders = ref<Pagination<Profile>>()

function openProfile(id: number) {
  router.push(`/profile/${id}`)
}

onMounted(async () => {
  traders.value = await getTraders()
})
</script>