import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "flex space-x-3" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "bg-zinc-800 rounded-2xl px-4 py-2" }
const _hoisted_4 = { class: "font-semibold inline-flex" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "flex items-center space-x-4 mt-1 text-sm text-gray-500" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_9 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_10 = { class: "mt-2 flex justify-end space-x-2" }
const _hoisted_11 = {
  key: 2,
  class: "mt-2 space-y-4"
}
const _hoisted_12 = { class: "flex-grow" }
const _hoisted_13 = { class: "bg-zinc-800 rounded-2xl px-4 py-2" }
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "flex items-center space-x-4 mt-1 text-sm text-gray-500" }
const _hoisted_17 = ["disabled", "onClick"]
const _hoisted_18 = {
  key: 0,
  class: "flex items-center"
}

import { AvatarImage } from '@/components/ui/avatar';
import Avatar from '@/components/ui/avatar/Avatar.vue';
import AvatarFallback from '@/components/ui/avatar/AvatarFallback.vue';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useAxios } from '@/composables/useAxios';
import { useDate } from '@/composables/useDate';
import { useTournament } from '@/composables/useTournament';
import { CommunityPostReactionContentEnum } from '@/enums/course/communities/communityPosts/communityPostReactions/CommunityPostReactionContentEnum';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { CommunityPost } from '@/interfaces/courses/communities/CommunityPost';
import { Pagination } from '@/interfaces/Pagination';
import { Handshake, Loader2, ThumbsUpIcon } from 'lucide-vue-next';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommunityPostComponent',
  props: {
    post: {}
  },
  setup(__props: any) {

const { hasActiveParticipation } = useTournament()
const props = __props
const axios = useAxios()
const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const replyingTo = ref<number | null>(null)
const replyContent = ref('')
const postReplyPayload = ref({
  content: ''
})
const isCreatingPostReply = ref(false)
const replies = ref<Pagination<CommunityPost>>()
const isLoading = ref(false)
const { timeAgo } = useDate()
const authUserReaction = computed(() => props.post.reactions.find((reaction) => reaction.userId === userAuthenticated.userId))
const profileAvatar = computed(() =>
  props.post.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);

const likePost = (id: number, content: CommunityPostReactionContentEnum) => {
  axios.post(`/courses/communities/topics/${props.post.communityTopicId}/posts/${id}/reactions`, { content })
  
  if (id === props.post.id) {
    const postAlreadyReacted = props.post.reactions.find((r) => r.userId === userAuthenticated.userId)
  
    if (!postAlreadyReacted) {
      props.post.reactions.push({
        content,
        userId: userAuthenticated.userId
      })
    } else {
      props.post.reactions = props.post.reactions.filter((r) => r.userId !== userAuthenticated.userId)
    }
  } else {
    const replyIndex = replies.value!.data.findIndex((r) => r.id === id)

    const postAlreadyReacted = replies.value!.data[replyIndex].reactions.find((r) => r.userId === userAuthenticated.userId)
    
    if (!postAlreadyReacted) {
      replies.value!.data[replyIndex].reactions.push({
        content,
        userId: userAuthenticated.userId
      })
    } else {
      replies.value!.data[replyIndex].reactions = replies.value!.data[replyIndex].reactions.filter((r) => r.userId !== userAuthenticated.userId)
    }
  }
}

const toggleReply = (id: number) => {
  replyingTo.value = replyingTo.value === id ? null : id
  replyContent.value = ''
}

const cancelReply = () => {
  replyingTo.value = null
  replyContent.value = ''
}

function getPostReplies(parentId: number, reset = false) {
  if (replies.value?.pagination.nextPage || !replies.value) {
    isLoading.value = true

    axios.get(`/courses/communities/topics/${props.post.communityTopicId}/posts`, { params: { parentId, page: replies.value?.pagination.nextPage ?? 1 } })
      .then((res) => {
        replies.value = reset ? res.data : { data: [...(replies.value?.data ?? []), ...res.data.data], pagination: res.data.pagination }
        isLoading.value = false
      })
  }
}

function createPostReply(payload: { content: string, parentId: number }) {
  isCreatingPostReply.value = true

  axios.post(`/courses/communities/topics/${props.post.communityTopicId}/posts`, payload)
    .then(() => {
      if (replies.value) {
        replies.value.pagination.nextPage = 1
      }

      getPostReplies(payload.parentId, true)
      props.post.repliesCount = (props.post.repliesCount ?? 0) + 1

      postReplyPayload.value = {
        content: ''
      }
    })
    .finally(() => {
      isCreatingPostReply.value = false
    })
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: { name: 'profile', params: { profileId: _ctx.post.authorId } }
    }, {
      default: _withCtx(() => [
        _createVNode(Avatar, null, {
          default: _withCtx(() => [
            (profileAvatar.value)
              ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                  key: 0,
                  src: profileAvatar.value.bucketLocation,
                  alt: _ctx.post.author.name
                }, null, 8, ["src", "alt"]))
              : _createCommentVNode("", true),
            _createVNode(AvatarFallback, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.post.author.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          class: "inline-flex gap-2",
          to: { name: 'profile', params: { profileId: _ctx.post.authorId } }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.post.author.name), 1),
            (_ctx.post.author.isTrader)
              ? (_openBlock(), _createBlock(_unref(Handshake), {
                  key: 0,
                  class: "text-green-500 w-5 h-5 relative",
                  title: "Trader Verificado"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.post.content), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(TooltipProvider), null, {
          default: _withCtx(() => [
            _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass({ 'cursor-not-allowed': !_unref(hasActiveParticipation) })
                    }, [
                      _createElementVNode("button", {
                        disabled: !_unref(hasActiveParticipation),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (likePost(_ctx.post.id, _unref(CommunityPostReactionContentEnum).LIKE))),
                        class: _normalizeClass(["transition duration-200", { 'text-blue-500': !!authUserReaction.value,'cursor-not-allowed': !_unref(hasActiveParticipation), 'hover:text-blue-500': _unref(hasActiveParticipation) }])
                      }, " Curtir ", 10, _hoisted_7)
                    ], 2)
                  ]),
                  _: 1
                }),
                (!_unref(hasActiveParticipation))
                  ? (_openBlock(), _createBlock(_unref(TooltipContent), { key: 0 }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("p", null, "Ação bloqueada! É necessário estar participando de um torneio.", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleReply(_ctx.post.id))),
          class: "hover:text-blue-500 transition duration-200"
        }, " Responder "),
        _createElementVNode("span", null, _toDisplayString(_unref(timeAgo)(_ctx.post.createdAt)), 1),
        (_ctx.post.reactions.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _createVNode(_unref(ThumbsUpIcon), { class: "w-4 h-4 text-blue-500 mr-1" }),
              _createTextVNode(" " + _toDisplayString(_ctx.post.reactions.length), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.post.repliesCount && !replies.value?.data.length)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["mt-2 flex items-center", { 'text-zinc-400': isLoading.value }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (getPostReplies(_ctx.post.id)))
          }, [
            (isLoading.value)
              ? (_openBlock(), _createBlock(_unref(Loader2), {
                  key: 0,
                  class: "w-4 h-4 mr-2 animate-spin"
                }))
              : _createCommentVNode("", true),
            (_ctx.post.repliesCount === 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("Ver " + _toDisplayString(_ctx.post.repliesCount) + " resposta", 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode("Ver todas as " + _toDisplayString(_ctx.post.repliesCount) + " respostas", 1)
                ], 64))
          ], 2))
        : _createCommentVNode("", true),
      (replyingTo.value === _ctx.post.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_unref(Textarea), {
              modelValue: postReplyPayload.value.content,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((postReplyPayload.value.content) = $event)),
              placeholder: "Escreva uma resposta...",
              rows: "2"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(Button), {
                onClick: cancelReply,
                variant: "ghost"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Cancelar ")
                ])),
                _: 1
              }),
              _createVNode(_unref(TooltipProvider), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass({ 'cursor-not-allowed': !_unref(hasActiveParticipation) })
                          }, [
                            _createVNode(_unref(Button), {
                              disabled: !postReplyPayload.value.content || isCreatingPostReply.value || !_unref(hasActiveParticipation),
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (createPostReply({ ...postReplyPayload.value, parentId: _ctx.post.id })))
                            }, {
                              default: _withCtx(() => [
                                (isCreatingPostReply.value)
                                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                                      key: 0,
                                      class: "w-4 h-4 mr-2 animate-spin"
                                    }))
                                  : _createCommentVNode("", true),
                                _cache[8] || (_cache[8] = _createTextVNode(" Responder "))
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ], 2)
                        ]),
                        _: 1
                      }),
                      (!_unref(hasActiveParticipation))
                        ? (_openBlock(), _createBlock(_unref(TooltipContent), { key: 0 }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createElementVNode("p", null, "Ação bloqueada! É necessário estar participando de um torneio.", -1)
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (replies.value && _ctx.post.repliesCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(replies.value.data, (reply) => {
              return (_openBlock(), _createElementBlock("div", {
                key: reply.id,
                class: "flex space-x-3 ml-8"
              }, [
                _createVNode(_component_RouterLink, {
                  to: { name: 'profile', params: { profileId: reply.authorId } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(Avatar, null, {
                      default: _withCtx(() => [
                        (reply.author.media.find((m) => m.type === _unref(ProfileMediaTypeEnum).AVATAR))
                          ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                              key: 0,
                              src: reply.author.media.find((m) => m.type === _unref(ProfileMediaTypeEnum).AVATAR)!.bucketLocation,
                              alt: reply.author.name
                            }, null, 8, ["src", "alt"]))
                          : _createCommentVNode("", true),
                        _createVNode(AvatarFallback, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(reply.author.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_RouterLink, {
                      class: "inline-flex gap-2",
                      to: { name: 'profile', params: { profileId: reply.authorId } }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", _hoisted_14, _toDisplayString(reply.author.name), 1),
                        (reply.author.isTrader)
                          ? (_openBlock(), _createBlock(_unref(Handshake), {
                              key: 0,
                              class: "text-green-500 w-5 h-5 relative",
                              title: "Trader Verificado"
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createElementVNode("p", _hoisted_15, _toDisplayString(reply.content), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(TooltipProvider), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass({ 'cursor-not-allowed': !_unref(hasActiveParticipation) })
                                }, [
                                  _createElementVNode("button", {
                                    disabled: !_unref(hasActiveParticipation),
                                    onClick: ($event: any) => (likePost(reply.id, _unref(CommunityPostReactionContentEnum).LIKE)),
                                    class: _normalizeClass(["transition duration-200", { 'text-blue-500': !!reply.reactions.length, 'cursor-not-allowed': !_unref(hasActiveParticipation), 'hover:text-blue-500': _unref(hasActiveParticipation) }])
                                  }, " Curtir ", 10, _hoisted_17)
                                ], 2)
                              ]),
                              _: 2
                            }, 1024),
                            (!_unref(hasActiveParticipation))
                              ? (_openBlock(), _createBlock(_unref(TooltipContent), { key: 0 }, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createElementVNode("p", null, "Ação bloqueada! É necessário estar participando de um torneio.", -1)
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", null, _toDisplayString(_unref(timeAgo)(reply.createdAt)), 1),
                    (reply.reactions.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                          _createVNode(_unref(ThumbsUpIcon), { class: "w-4 h-4 text-blue-500 mr-1" }),
                          _createTextVNode(" " + _toDisplayString(reply.reactions.length), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128)),
            (replies.value?.pagination?.nextPage)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: _normalizeClass(["pl-8 w-full text-center flex justify-center items-center", { 'text-zinc-400': replies.value && isLoading.value }]),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (getPostReplies(_ctx.post.id)))
                }, [
                  (replies.value && isLoading.value)
                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                        key: 0,
                        class: "w-4 h-4 mr-2 animate-spin"
                      }))
                    : _createCommentVNode("", true),
                  _cache[11] || (_cache[11] = _createTextVNode(" Ver mais respostas "))
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})