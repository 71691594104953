<template>
  <Card class="flex flex-col max-h-[1345px] overflow-auto relative">
    <CardHeader>
      <div class="flex justify-between items-center">
        <CardTitle>Comentários</CardTitle>
        <Button @click.prevent="$emit('onAdd', 'lesson'); isCommentDialogOpen = true; commentToEdit = undefined; payload = {}">Adicionar</Button>
      </div>
    </CardHeader>
    <CardContent class="flex-1">
      <div v-if="comments" class="min-w-[300px]">
        <div class="flex flex-col gap-4 border-b" v-for="comment in comments?.data">
          <div class="flex items-center justify-between hover:bg-muted transition-background duration-200 p-4">
            <div class="flex items-center gap-4">
              <div>
                <User2 class="h-7 w-7" />
              </div>
              <div>
                <h3>{{ comment.user.person.name }}</h3>
                <p class="text-zinc-400 text-xs">{{ comment.content }}</p>
              </div>
            </div>
            <div class="flex gap-2">
              <button v-if="comment.status === LessonCommentStatusEnum.INACTIVE" class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-success"
              @click="updateComment(comment.id, { status: LessonCommentStatusEnum.ACTIVE })">
                <CircleCheck class="w-5 h-5" />
              </button>
              <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-danger"
              @click.prevent="commentToDeleteId = comment.id; isDeleteCommentDialogOpen = true">
                <Trash2 class="w-5 h-5" />
              </button>
            </div>
            <!-- <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button
                  aria-haspopup="true"
                  size="icon"
                  variant="ghost"
                >
                  <MoreHorizontal class="h-4 w-4" :stroke-width="1.25" />
                  <span class="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                 <DropdownMenuItem @click="commentToEdit = comment; isCommentDialogOpen = true; payload = { content: comment.content }">Editar</DropdownMenuItem> -->
                <!-- <DropdownMenuItem @click="commentToDeleteId = comment.id; isDeleteCommentDialogOpen = true">Excluir</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> -->
          </div>
        </div>
        <PaginationApi :data="comments.pagination" :paginate="getComments" />
      </div>
      <SpinnerLoader v-else />
      <div v-if="comments && !comments.data.length">
        <p class="text-zinc-400">Nenhum comentário registrado.</p>
      </div>
    </CardContent>
  </Card>
  <Dialog :open="isCommentDialogOpen" @update:open="(open) => isCommentDialogOpen = open">
    <DialogContent class="sm:max-w-md">
      <DialogHeader>
        <DialogTitle class="text-[1.1rem]">{{commentToEdit ? 'Editar' : 'Adicionar'}} comentário</DialogTitle>
        <DialogDescription class="">
          Compartilhe sua opinião sobre a aula
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-3 py-6">
        <Label for="content">Comentário</Label>
        <Textarea
          placeholder="ex: Os detalhes que foram ensinados nesta aula são importantes..."
          class="min-h-32"
          id="content"
          v-model="payload.content"
        />
      </div>
      <DialogFooter class="sm:justify-start">
        <DialogClose as-child>
          <Button type="button" variant="secondary">
            Cancelar
          </Button>
        </DialogClose>
        <Button type="button" @click="commentToEdit ? '' : createComment()" :disabled="isLoading || !payload.content">
          <Loader2 v-if="isLoading" class="w-4 h-4 mr-2 animate-spin" />
          {{ commentToEdit ? 'Salvar' : 'Adicionar' }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
  <Dialog v-if="commentToDeleteId" :open="isDeleteCommentDialogOpen" @update:open="(open) => isDeleteCommentDialogOpen = open">
    <DialogContent class="sm:max-w-md">
      <DialogHeader>
        <div class="flex gap-2 items-center">
          <AlertTriangle class="h-6 w-6" />
          <DialogTitle class="text-[1.1rem]">Excluir Comentário</DialogTitle>
        </div>
        <DialogDescription class="py-2">
          Essa ação é permanente e não poderá ser desfeita. O comentário será removido imediatamente.
        </DialogDescription>
      </DialogHeader>
      <div class="flex items-center space-x-2">
        
      </div>
      <DialogFooter class="sm:justify-start">
        <DialogClose as-child>
          <Button type="button" variant="secondary">
            Cancelar
          </Button>
        </DialogClose>
        <Button type="button" :disabled="isDeletingComment" @click="deleteComment(commentToDeleteId)">
          <Loader2 v-if="isDeletingComment" class="w-4 h-4 mr-2 animate-spin" />
          Excluir Comentário
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script lang="ts" setup>
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { onMounted, ref } from 'vue';
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label';
import { useAxios } from '@/composables/useAxios';
import { Lesson } from '@/interfaces/lesson/Lessons';
import { toast } from '@/components/ui/toast';
import { AlertTriangle, CircleCheck, Loader2, MoreHorizontal, Trash2, User2 } from 'lucide-vue-next';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { LessonCommentStatusEnum } from '@/enums/lesson/lessonComment/LessonCommentStatusEnum';


const axios = useAxios()
const props = defineProps<{ lesson: Lesson }>()
defineEmits(['onAdd'])
const isCommentDialogOpen = ref(false)
const isLoading = ref(false)
const comments = ref<Pagination<LessonComment>>()
const commentToEdit = ref<LessonComment>()
const commentToDeleteId = ref<number>()
const isDeleteCommentDialogOpen = ref(false)
const isDeletingComment = ref(false)

const payload = ref<{ content?: string }>({
  content: ''
})

function createComment() {
  isLoading.value = true

  axios.post(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments`, payload.value)
    .then(() => {
      toast({
        title: 'Comentário cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      payload.value = {}
      isCommentDialogOpen.value = false
      getComments()
    })
    .finally(() => isLoading.value = false)
}

function updateComment(commentId: number, payload: { status: LessonCommentStatusEnum }) {
  isLoading.value = true

  axios.patch(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments/${commentId}`, payload)
    .then(() => {
      toast({
        title: 'Comentário atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })

      const commentIndex = comments.value?.data.findIndex((comment) => comment.id === commentId)

      if (commentIndex) {
        comments.value!.data[commentIndex] = { ...comments.value!.data[commentIndex], ...payload }
      }

      isCommentDialogOpen.value = false
    })
    .finally(() => isLoading.value = false)
}

function deleteComment(commentId: number) {
  axios.delete(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments/${commentId}`)
    .then(() => {
      toast({
        title: 'Comentário excluído com sucesso',
        class: 'bg-green-500 text-white'
      })
      isDeleteCommentDialogOpen.value = false
      comments.value!.data = comments.value!.data.filter((comment) => comment.id !== commentId)
    })
}

function getComments(page = 1) {
  axios.get(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

onMounted(() => {
  getComments()
})
</script>

<style lang="scss" scoped>
*::-webkit-scrollbar {
  width: 0.4125rem !important;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  border-radius: 15rem;
}
</style>