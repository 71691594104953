<template>
  <div class="w-full max-w-full overflow-hidden lg:w-[821px] p-4 sm:p-0" v-if="community">
    <div class="bg-card mb-10 rounded-lg border" v-if="communityBanner">
      <img class="h-full object-cover w-full" style="max-height: 350px" :src="communityBanner.bucketLocation" alt="">
      <div class="p-6">
        <p class="text-[1.1rem] font-bold">{{ community.name }}</p>
        <!-- <p class="text-zinc-400">300 alunos</p> -->
         <div class="flex items-center gap-2 py-2">
           <RouterLink v-if="isCommunityOwner" :to="{ name: 'courses.communities.edit', params: { communityId: community.id } }">
             <SquarePen class="h-5 w-5 mr-2" />
           </RouterLink>
           <button @click="isDeleteCommunityConfirmationDialogOpen = true" v-if="isCommunityOwner">
             <Trash2 class="h-5 w-5 mr-2" />
           </button>
         </div>
      </div>
    </div>
    <!-- Feed principal -->
    <div class="flex gap-">
      <section class="w-full space-y-6">
        <!-- Criar post -->
        <Card class="">
          <CardHeader>
            <CardTitle class="text-[1.0rem]">Criar Publicação</CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea v-model="topicPayload.content" placeholder="No que você está pensando?" />
          </CardContent>
          <CardFooter class="flex justify-between">
            <!-- <Button variant="ghost" size="sm">
              <ImageIcon class="h-5 w-5 mr-2" />
              Foto
            </Button>
            <Button variant="ghost" size="sm">
              <VideoIcon class="h-5 w-5 mr-2" />
              Vídeo
            </Button> -->
            <Button :disabled="isCreatingTopic || !topicPayload.content" @click="createCommunityTopic">
            <Loader2 v-if="isCreatingTopic" class="w-4 h-4 mr-2 animate-spin" />
              Publicar
            </Button>
          </CardFooter>
        </Card>

        <div class="grid gap-6" ref="topicsWrapper" v-if="topics">
          <!-- <CommunityTopic v-for="post in posts.data" :key="post.id" :post="post" /> -->
          <!-- Posts -->
          <Topic v-for="(topic, index) in topics.data" :key="topic.id" :topic="topic" @delete="topics.data.splice(index, 1)" :community="community" />
        </div>
        <div class="grid gap-6" v-if="areTopicsLoading">
          <div class="h-[260px] w-full bg-muted rounded-lg" v-for="i in [1,2,3,4]"></div>
        </div>
        <div v-if="topics && !topics.data.length" className="rounded-lg p-6 pb-14 mb-8">
          <div className="flex items-center justify-center mb-4">
            <Sparkles className="h-12 w-12 text-yellow-400" />
          </div>
          <h3 className="text-xl font-semibold text-white text-center mb-2">Sem publicações por aqui</h3>
          <p className="text-gray-300 text-center">
            Esta comunidade está esperando por sua primeira publicação. Por que não fazer um?
          </p>
        </div>
      </section>

      <!-- Barra lateral direita -->
      <!-- <aside class="hidden xl:block w-[700px] pl-6 space-y-6"> -->
        <!-- Sugestões de amizade -->
        <!-- <Card class="">
          <CardHeader>
            <CardTitle>Sugestões de Amizade</CardTitle>
          </CardHeader>
          <CardContent>
            <ul class="space-y-4">
              <li v-for="friend in friendSuggestions" :key="friend.id" class="flex items-center justify-between">
                <div class="flex items-center space-x-3">
                  <Avatar>
                    <AvatarImage :src="friend.avatar" :alt="friend.name" />
                    <AvatarFallback>{{ friend.initials }}</AvatarFallback>
                  </Avatar>
                  <span>{{ friend.name }}</span>
                </div>
                <Button size="sm">Adicionar</Button>
              </li>
            </ul>
          </CardContent>
        </Card> -->

        <!-- Eventos próximos -->
        <!-- <Card class="">
          <CardHeader>
            <CardTitle>Eventos Próximos</CardTitle>
          </CardHeader>
          <CardContent>
            <ul class="space-y-4">
              <li v-for="event in upcomingEvents" :key="event.id" class="flex items-center space-x-3">
                <CalendarIcon class="h-5 w-5 text-blue-500" />
                <div>
                  <p class="font-medium">{{ event.name }}</p>
                  <p class="text-sm text-gray-400">{{ event.date }}</p>
                </div>
                <Button 
                  variant="outline" 
                  size="sm" 
                  class="text-gray-200 hover:bg-gray-700"
                >
                  <ArrowRightIcon class="h-4 w-4 mr-1" />
                </Button>
              </li>
            </ul>
          </CardContent>
        </Card> -->
      <!-- </aside> -->
    </div>
    <Dialog :open="isDeleteCommunityConfirmationDialogOpen" @update:open="(open) => isDeleteCommunityConfirmationDialogOpen = open">
      <DialogContent class="sm:max-w-md">
        <DialogHeader>
          <div class="flex gap-2 items-center">
            <AlertTriangle class="h-6 w-6" />
            <DialogTitle class="text-[1.1rem]">Cuidado</DialogTitle>
          </div>
          <DialogDescription class="py-2">
            Deseja mesmo excluir essa comunidade?
          </DialogDescription>
        </DialogHeader>
        <div class="flex items-center space-x-2">
          
        </div>
        <DialogFooter class="sm:justify-start">
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              Cancelar
            </Button>
          </DialogClose>
          <Button type="button" :disabled="isDeletingCommunity" @click="deleteCommunity()">
            <Loader2 v-if="isDeletingCommunity" class="w-4 h-4 mr-2 animate-spin" />
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
  <SpinnerLoader v-else />
  <Toaster />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Loader2, Sparkles, SquarePen, Trash2, AlertTriangle } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { Community } from '@/interfaces/courses/communities/Community'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Pagination } from '@/interfaces/Pagination'
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic'
import Topic from '@/views/feed/components/topic/Topic.vue'
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum'
import { useAuth } from '@/composables/useAuth'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import router from '@/router'
import { communities } from '@/states/communities';
import { toast, Toaster } from '@/components/ui/toast'

const topicsWrapper = ref<HTMLElement | null>(null)
const props = defineProps<{ courseId: number, communityId: number }>()
const axios = useAxios()
const communityBanner = computed(() => community.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const topicPayload = ref({
  content: ''
})
const { userAuthenticated } = useAuth()
const areTopicsLoading = ref(false)
const isCommunityOwner = computed(() => userAuthenticated.organizationIds.includes(community.value?.organizationId))
const community = ref<Community>()
const topics = ref<Pagination<CommunityTopic>>()
const isCreatingTopic = ref(false)
const isDeletingCommunity = ref(false)
const isDeleteCommunityConfirmationDialogOpen = ref(false)

// const friendSuggestions = ref([
//   { id: 1, name: 'Carlos Oliveira', avatar: '/placeholder.svg?height=40&width=40', initials: 'CO' },
//   { id: 2, name: 'Ana Rodrigues', avatar: '/placeholder.svg?height=40&width=40', initials: 'AR' },
// ])

// const upcomingEvents = ref([
//   { id: 1, name: 'Meetup de Desenvolvedores', date: '15 de Maio, 19:00' },
//   { id: 2, name: 'Workshop de Vue.js', date: '22 de Maio, 14:00' },
// ])

function getCommunity() {
  community.value = undefined

  axios.get(`/courses/${props.courseId}/communities/${props.communityId}`)
    .then((res) => {
      community.value = res.data
    })
}

function getCommunityTopics({ hasLoading } = { hasLoading: true }) {
  const page = topics.value?.pagination.nextPage ?? 1;
  
  if (hasLoading) {
    areTopicsLoading.value = true;
  }

  axios.get(`/courses/${props.courseId}/communities/${props.communityId}/topics`, { params: { page } })
    .then((res) => {
      topics.value = { ...res.data, data: topics.value && topics.value?.pagination.nextPage ? [...topics.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => areTopicsLoading.value = false)
}

function createCommunityTopic() {
  isCreatingTopic.value = true

  axios.post(`/courses/communities/topics`, { communityId: props.communityId, ...topicPayload.value })
    .then((res) => {
      getCommunityTopics({ hasLoading: false })
      topicPayload.value = {
        content: ''
      }
    })
    .finally(() => isCreatingTopic.value = false)
}

const handleScroll = () => {
  if ((topicsWrapper.value && (topicsWrapper.value?.getBoundingClientRect().bottom < window.innerHeight)) && 
      (topics.value?.pagination.nextPage) && 
      !areTopicsLoading.value) {
    getCommunityTopics()
  }
}

function deleteCommunity() {
  isDeletingCommunity.value = true

  axios.delete(`/courses/${community.value!.courseId}/communities/${community.value!.id}`)
    .then(() => {
      toast({
        title: 'Comunidade excluída com sucesso',
        class: 'bg-green-500 text-white'
      })
      router.push({ name: 'feed' })
      communities.value = communities.value?.filter((c) => c.id !== community.value!.id)
    })
    .catch(() => isDeletingCommunity.value = false)
    .finally(() => {
      isDeletingCommunity.value = false
      isDeleteCommunityConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getCommunityTopics()
  getCommunity()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

watch(() => props.communityId, () => {
  topics.value = undefined
  getCommunityTopics()
  getCommunity()
})
</script>