<template>
  <Pagination
    v-if="data && data.total"
    :total="data.total"
    :sibling-count="1"
    show-edges
    :default-page="data.page"
    class="pt-6"
  >
    <PaginationList v-slot="{ items }" class="flex items-center gap-1">
      <!-- <PaginationFirst @click="paginate(1)" /> -->
      <PaginationPrev class="w-8 h-8" @click="paginate(data.prevPage!)" :disabled="!data.prevPage" />

      <PaginationListItem v-if="data.prevPage !== 1 && data.page !== 1" :value="1" as-child>
        <Button class="w-8 h-8 p-0" :variant="'outline'" @click="paginate(1)">
          1
        </Button>
      </PaginationListItem>

      <PaginationListItem :value="data.page" as-child>
        <Button class="w-8 h-8 p-0" :variant="'default'">
          {{ data.page }}
        </Button>
      </PaginationListItem>

      <PaginationListItem v-if="data.nextPage" :value="data.nextPage" as-child>
        <Button class="w-8 h-8 p-0" :variant="'outline'" @click="paginate(data.nextPage)">
          {{ data.nextPage }}
        </Button>
      </PaginationListItem>

      <PaginationEllipsis v-if="displayLastPage" />

      <PaginationListItem v-if="displayNextToLast" :value="data.lastPage - 1" as-child>
        <Button class="w-8 h-8 p-0" :variant="'outline'" @click="paginate(data.lastPage - 1)">
          {{ (data.lastPage - 1) }}
        </Button>
      </PaginationListItem>

      <PaginationListItem v-if="displayLastPage" :value="data.lastPage" as-child>
        <Button class="w-8 h-8 p-0" :variant="'outline'" @click="paginate(data.lastPage)">
          {{ data.lastPage }}
        </Button>
      </PaginationListItem>

      <PaginationNext class="w-8 h-8" @click="paginate(data.nextPage!)" :disabled="!data.nextPage" />
      <!-- <PaginationLast @click="paginate(data.lastPage)" /> -->
    </PaginationList>
  </Pagination>
</template>

<script setup lang="ts">
import { Pagination, PaginationList, PaginationPrev, PaginationNext, PaginationListItem, PaginationEllipsis, PaginationLast } from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'
import { computed } from 'vue'

interface IPaginationData {
  total: number
  lastPage: number
  prevPage?: number
  nextPage: number
  perPage: number
  page: number
  from: number
  to: number
}

const props = defineProps<{ data: IPaginationData, paginate: (page: number) => void }>()

const displayNextToLast = computed(() => 
  !!(props.data.lastPage - 1) && 
  (props.data.lastPage - 1) !== props.data.nextPage && 
  (props.data.lastPage - 1) !== props.data.page && 
  (props.data.lastPage - 1) !== props.data.prevPage
)

const displayLastPage = computed(() => 
  props.data.lastPage !== props.data.nextPage && 
  props.data.lastPage !== props.data.page
)
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
</style>
