import { ref } from "vue"
import { useAxios } from "./useAxios"

const hasActiveParticipation = ref(false)

export function useTournament() {
  const axios = useAxios()

  async function checkActiveParticipation(): Promise<boolean> {
    const now = new Date()
    const lastCheckActiveParticipation = localStorage.getItem("tournament.lastCheckActiveParticipation")
    const hasActiveParticipationLocal = localStorage.getItem("tournament.hasActiveParticipation")

    // Se já foi verificado hoje, retorna o valor armazenado
    if (lastCheckActiveParticipation && hasActiveParticipationLocal) {
      const lastCheckActiveParticipationDate = new Date(lastCheckActiveParticipation)
      if (
        now.getFullYear() === lastCheckActiveParticipationDate.getFullYear() &&
        now.getMonth() === lastCheckActiveParticipationDate.getMonth() &&
        now.getDate() === lastCheckActiveParticipationDate.getDate()
      ) {
        hasActiveParticipation.value = true

        return JSON.parse(hasActiveParticipationLocal)
      }
    }

    // Faz a verificação e atualiza o localStorage
    try {
      const response = await axios.get("/tournaments/check-active-participation")
      const status = response.data

      localStorage.setItem("tournament.lastCheckActiveParticipation", now.toISOString())
      localStorage.setItem("tournament.hasActiveParticipation", JSON.stringify(status))

      hasActiveParticipation.value = true

      return status
    } catch (error) {
      console.error("Erro ao verificar participação ativa:", error)
      return false
    }
  }

  return {
    checkActiveParticipation,
    hasActiveParticipation
  }
}