<template>
  <aside class="hidden lg:block w-[285px] pr-6 relative">
    <nav class="space-y-2 sticky top-[100px]">
      <RouterLink class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :to="{ name: 'feed' }" :active-class="'bg-accent'">
        <component :is="GlobeIcon" class="h-5 w-5 mr-2" />
        Explorar
      </RouterLink>
      <RouterLink v-if="hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN]) && tenant?.id === 1 || hasRoles([AclEnum.ADMIN_MEMBER_AREA])" class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :to="{ name: 'courses.communities.create' }" :active-class="'bg-accent'">
        <component :is="FolderPlusIcon" class="h-5 w-5 mr-2" />
        Adicionar comunidade
      </RouterLink>
      <template v-if="!communities">
        <a
          v-for="i in 5"
          :key="i"
          class="inline-flex h-10 px-4 py-2 w-full rounded-md animate-pulse bg-accent/20"
        ></a>
      </template>
      <template v-else>
        <RouterLink class="truncate inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :active-class="'bg-accent'" :to="{ name: 'courses.communities.show', params: { courseId: community.courseId, communityId: community.id } }" v-for="community in communities" :key="community.id">
          {{ community.name }}
        </RouterLink>
      </template>
    </nav>
  </aside>
  <Sheet>
    <SheetTrigger as-child>
      <button
        v-if="!$route.path.includes('/topic')"
        class="bg-background border shrink-0 md:hidden fixed rounded-full bottom-5 right-4 p-5"
      >
        <Menu class="h-5 w-5" />
        <span class="sr-only">Toggle navigation menu</span>
      </button>
    </SheetTrigger>
    <SheetContent side="left">
      <nav class="grid gap-4 text-lg font-medium pt-8">
        <RouterLink class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :to="{ name: 'feed' }" :active-class="'bg-accent'">
          <component :is="GlobeIcon" class="h-5 w-5 mr-2" />
          Explorar
        </RouterLink>
        <RouterLink v-if="hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN]) && tenant?.id === 1 || hasRoles([AclEnum.ADMIN_MEMBER_AREA])" class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :to="{ name: 'courses.communities.create' }" :active-class="'bg-accent'">
          <component :is="FolderPlusIcon" class="h-5 w-5 mr-2" />
          Adicionar comunidade
        </RouterLink>
        <template v-if="!communities">
          <a
            v-for="i in 5"
            :key="i"
            class="inline-flex h-10 px-4 py-2 w-full rounded-md animate-pulse bg-accent/20"
          ></a>
        </template>
        <template v-else>
          <RouterLink class="truncate inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start" :active-class="'bg-accent'" :to="{ name: 'courses.communities.show', params: { courseId: community.courseId, communityId: community.id } }" v-for="community in communities" :key="community.id">
            {{ community.name }}
          </RouterLink>
        </template>
      </nav>
    </SheetContent>
  </Sheet>
</template>

<script setup lang="ts">
import Button from '@/components/ui/button/Button.vue';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useAuth } from '@/composables/useAuth';
import { useAxios } from '@/composables/useAxios';
import { AclEnum } from '@/enums/acl/AclEnum';
import { communities } from '@/states/communities';
import { FolderPlusIcon, GlobeIcon, Menu } from 'lucide-vue-next';
import { onMounted } from 'vue';
import { useTenant } from '@/composables/useTenant'

const { tenant } = useTenant()
const { hasRoles } = useAuth()
const axios = useAxios()

function getCommunities() {
  axios.get('/courses/communities')
    .then((res) => {
      communities.value = res.data
    })
}

onMounted(() => {
  getCommunities()
})
</script>
