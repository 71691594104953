<template>
  <div class="p-4 sm:p-8">
    <div class="sm:container mx-auto">
      <h1 class="text-[1.1rem] font-bold text-white mb-8">Conquistas</h1>
      
      <div v-if="achievements" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="achievement in achievements" 
             :key="achievement.id" 
             class="bg-card rounded-lg p-6 transition-transform hover:scale-105">
          <div class="flex items-start space-x-4">
            <img :src="achievement.icon" class="w-[80px]" alt="">
            <!-- <div class="p-3 bg-purple-500 bg-opacity-20 rounded-lg">
            </div> -->
            <div class="flex-1">
              <h3 class="text-[1.0rem] font-semibold text-white mb-2">
                {{ achievement.title }}
              </h3>
              <p class="text-gray-400 text-sm text-[.9rem]">
                {{ achievement.description }}
              </p>
            </div>
          </div>
          <!-- <div class="mt-4 flex items-center space-x-2">
            <div class="h-2 flex-1 bg-gray-700 rounded-full overflow-hidden">
              <div 
                class="h-full bg-purple-500 rounded-full transition-all duration-300"
                :style="{ width: `${achievement.progress}%` }"
              ></div>
            </div>
            <span class="text-sm text-gray-400">{{ achievement.progress }}%</span>
          </div> -->
        </div>
      </div>
      <SpinnerLoader v-else />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useAchievement } from '@/composables/useAchievement'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Achievement } from '@/interfaces/achievements/Achievement';

const achievements = ref<Achievement[]>()

const { getAllAchievements } = useAchievement()

onMounted(() => {
  getAllAchievements()
    .then(data => achievements.value = data)
})
</script>