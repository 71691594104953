import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:gap-4 sm:py-4" }
const _hoisted_2 = { class: "grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-auto flex items-center gap-2" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex gap-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "text-xs text-muted-foreground"
}
const _hoisted_9 = { class: "flex gap-2 items-center" }

import {
  MoreHorizontal,
  PlusCircle,
  AlertTriangle,
  Loader2,
  Pencil,
  Trash2
} from 'lucide-vue-next'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  Tabs,
  TabsContent,
} from '@/components/ui/tabs'

import { onMounted, ref } from 'vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Pagination as IPagination }from '@/interfaces/Pagination'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { Dialog } from '@/components/ui/dialog'
import DialogContent from '@/components/ui/dialog/DialogContent.vue'
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue'
import DialogClose from '@/components/ui/dialog/DialogClose.vue'
import { toast, Toaster } from '@/components/ui/toast'
import { CourseUser } from '@/interfaces/courses/users/CourseUser'
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum'


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexCourseUsersView',
  props: {
    courseId: {}
  },
  setup(__props: any) {

const props = __props
const axios = useAxios()
const courseUsers = ref<IPagination<CourseUser>>()
const isdeleteCourseUserUserConfirmationDialogOpen = ref(false)
const selectedCourseUserIdToDelete = ref<number>()
const isDeletingCourseUser = ref(false)

function getCourseUsers(page = 1) {
  axios.get(`/courses/${props.courseId}/users`, { params: { page } })
    .then((res) => {
      courseUsers.value = res.data
    })
}

function deleteCourseUser(id: number) {
  isDeletingCourseUser.value = true

  axios.delete(`/courses/${props.courseId}/users/${id}`)
    .then(() => {
      toast({
        title: 'Aluno excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      courseUsers.value!.data = courseUsers.value!.data.filter((course) => course.id !== id)
    })
    .catch(() => isDeletingCourseUser.value = false)
    .finally(() => {
      isDeletingCourseUser.value = false
      isdeleteCourseUserUserConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getCourseUsers()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_unref(Tabs), { "default-value": "all" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(Button), {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'courses.users.create', params: { courseId: _ctx.courseId } }))),
                size: "sm",
                class: "h-9 gap-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(PlusCircle), { class: "h-3.5 w-3.5" }),
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only sm:not-sr-only sm:whitespace-nowrap" }, " Adicionar Aluno ", -1))
                ]),
                _: 1
              })
            ])
          ]),
          _createVNode(_unref(TabsContent), { value: "all" }, {
            default: _withCtx(() => [
              _createVNode(_unref(Card), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(CardHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Alunos")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(CardDescription), null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Gerencie, crie e edite os seus alunos ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(CardContent), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Table), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TableHeader), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TableRow), null, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(TableHead), { class: "hidden w-[100px] sm:table-cell" }, {
                                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                                      _createElementVNode("span", { class: "sr-only" }, "img", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(TableHead), null, {
                                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                                      _createTextVNode("Nome")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(TableHead), null, {
                                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                                      _createTextVNode("Email")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(TableHead), null, {
                                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                                      _createTextVNode("Registrado em")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(TableHead), null, {
                                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createTextVNode(" Ações ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (courseUsers.value)
                            ? (_openBlock(), _createBlock(_unref(TableBody), { key: 0 }, {
                                default: _withCtx(() => [
                                  (courseUsers.value && !!courseUsers.value.data.length)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(courseUsers.value.data, (user) => {
                                        return (_openBlock(), _createBlock(_unref(TableRow), null, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(TableCell), { class: "hidden sm:table-cell" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("img", {
                                                  alt: "Imagem do curso",
                                                  class: "aspect-square rounded-md object-cover",
                                                  height: "64",
                                                  src: user.profile.media!.find((m) => m.type === _unref(ProfileMediaTypeEnum).AVATAR)?.bucketLocation ?? '/assets/img/no-image.png',
                                                  onError: _cache[1] || (_cache[1] = ($event: any) => (($event.target as HTMLInputElement).src = '/assets/img/no-image.png')),
                                                  width: "64"
                                                }, null, 40, _hoisted_5)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_unref(TableCell), { class: "font-medium" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(user.profile.name), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_unref(TableCell), { class: "font-medium" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(user.user.email), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_unref(TableCell), { class: "font-medium" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(new Date(user.createdAt).toLocaleDateString()), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_unref(TableCell), null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_6, [
                                                  _createElementVNode("button", {
                                                    class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg",
                                                    onClick: ($event: any) => {isdeleteCourseUserUserConfirmationDialogOpen.value = true; selectedCourseUserIdToDelete.value = user.id}
                                                  }, [
                                                    _createVNode(_unref(Trash2), { class: "w-5 h-5" })
                                                  ], 8, _hoisted_7)
                                                ])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 256))
                                    : (_openBlock(), _createBlock(_unref(TableRow), { key: 1 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(TableCell), {
                                            colspan: "5",
                                            class: "text-center font-medium py-8"
                                          }, {
                                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                                              _createTextVNode(" Nenhum aluno encontrado. ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      (!courseUsers.value)
                        ? (_openBlock(), _createBlock(SpinnerLoader, { key: 0 }))
                        : _createCommentVNode("", true),
                      (courseUsers.value)
                        ? (_openBlock(), _createBlock(PaginationApi, {
                            key: 1,
                            data: courseUsers.value.pagination,
                            paginate: (page) => { courseUsers.value = undefined; getCourseUsers(page) }
                          }, null, 8, ["data", "paginate"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(CardFooter), null, {
                    default: _withCtx(() => [
                      (courseUsers.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _cache[13] || (_cache[13] = _createTextVNode(" Exibindo ")),
                            _createElementVNode("strong", null, "1-" + _toDisplayString(courseUsers.value.data.length), 1),
                            _cache[14] || (_cache[14] = _createTextVNode(" de ")),
                            _createElementVNode("strong", null, _toDisplayString(courseUsers.value.pagination.total), 1),
                            _cache[15] || (_cache[15] = _createTextVNode(" resultados "))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (selectedCourseUserIdToDelete.value)
                ? (_openBlock(), _createBlock(_unref(Dialog), {
                    key: 0,
                    open: isdeleteCourseUserUserConfirmationDialogOpen.value,
                    "onUpdate:open": _cache[3] || (_cache[3] = (open) => isdeleteCourseUserUserConfirmationDialogOpen.value = open)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(DialogContent, { class: "sm:max-w-md" }, {
                        default: _withCtx(() => [
                          _createVNode(DialogHeader, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                                _createVNode(DialogTitle, { class: "text-[1.1rem]" }, {
                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode("Cuidado")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _createVNode(DialogDescription, { class: "py-2" }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode(" Deseja mesmo deletar esse aluno? ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                          _createVNode(DialogFooter, { class: "sm:justify-start" }, {
                            default: _withCtx(() => [
                              _createVNode(DialogClose, { "as-child": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Button), {
                                    type: "button",
                                    variant: "secondary"
                                  }, {
                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                      _createTextVNode(" Cancelar ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_unref(Button), {
                                type: "button",
                                disabled: isDeletingCourseUser.value,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (deleteCourseUser(selectedCourseUserIdToDelete.value)))
                              }, {
                                default: _withCtx(() => [
                                  (isDeletingCourseUser.value)
                                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                                        key: 0,
                                        class: "w-4 h-4 mr-2 animate-spin"
                                      }))
                                    : _createCommentVNode("", true),
                                  _cache[19] || (_cache[19] = _createTextVNode(" Confirmar "))
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["open"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(Toaster))
    ])
  ]))
}
}

})