import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full lg:w-[821px] p-4 sm:p-0 pt-0"
}
const _hoisted_2 = { class: "lg:w-[821px] mx-auto bg-card rounded-xl shadow-md overflow-hidden" }
const _hoisted_3 = { class: "p-6 border-b border-gray-200 min-w-full" }
const _hoisted_4 = { class: "flex items-center space-x-3 mb-4" }
const _hoisted_5 = { class: "text-lg mb-4 whitespace-pre-wrap" }
const _hoisted_6 = { class: "flex justify-between items-center text-gray-300 text-sm" }
const _hoisted_7 = { class: "flex items-center space-x-2" }
const _hoisted_8 = { class: "flex -space-x-1" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex justify-start py-2 border-b border-gray-200" }
const _hoisted_11 = {
  key: 0,
  class: "flex space-x-2"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "p-6" }
const _hoisted_15 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_16 = { class: "mt-6" }
const _hoisted_17 = { class: "flex items-start space-x-2" }
const _hoisted_18 = { class: "flex-grow" }
const _hoisted_19 = { class: "mt-2 flex justify-end" }

import { computed, onMounted, ref } from 'vue'
import { ThumbsUpIcon, Loader2, MoveLeft } from 'lucide-vue-next'
import { Textarea } from '@/components/ui/textarea'
import Button from '@/components/ui/button/Button.vue'
import { useAxios } from '@/composables/useAxios';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic';
import { Pagination } from '@/interfaces/Pagination';
import { CommunityPost } from '@/interfaces/courses/communities/CommunityPost';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useDate } from '@/composables/useDate';
import { CommunityTopicReactionContentEnum, communityTopicReactionIcons, communityTopicReactionLabels } from '@/enums/course/communities/communityTopics/communityTopicReactions/CommunityTopicReactionContentEnum';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import CommunityPostComponent from './components/CommunityPostComponent.vue';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { Profile } from '@/interfaces/profiles/Profile';
import { useAuth } from '@/composables/useAuth';
import ProfileComponent from '@/views/profile/components/ProfileComponent.vue';
import { useTournament } from '@/composables/useTournament';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowCommunityTopicView',
  props: {
    communityTopicId: {}
  },
  setup(__props: any) {

const { timeAgo } = useDate()
const { hasActiveParticipation } = useTournament()
const axios = useAxios()
const { userAuthenticated, getProfile } = useAuth()
const props = __props
const arePostsLoading = ref(false)
const distinctReactions = computed(() => {
  return Array.from(new Set(topic.value?.reactions.map(reaction => reaction.content))) as CommunityTopicReactionContentEnum[];
})
const topic = ref<CommunityTopic>()
const alreadyReacted = computed(() => topic.value?.reactions.find((r) => r.userId === userAuthenticated.userId))
const profileAvatar = computed(() =>
  topic.value?.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const authProfileAvatar = computed(() =>
  userAuthenticatedProfile.value?.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const posts = ref<Pagination<CommunityPost>>()
const postPayload = ref({
  content: ''
})
const isCreatingPost = ref(false)
const userAuthenticatedProfile = ref<Profile>()

const toggleReaction = async (content: CommunityTopicReactionContentEnum) => {
  axios.post(`/courses/communities/topics/${topic.value!.id}/reactions`, { content })

  if (!alreadyReacted.value) {
    topic.value!.reactions.push({
      content,
      userId: userAuthenticated.userId
    })
  } else {
    topic.value!.reactions = topic.value!.reactions.filter((r) => r.userId !== userAuthenticated.userId)
  }
}

function getTopic() {
  axios.get(`/courses/communities/topics/${props.communityTopicId}`)
    .then((res) => {
      topic.value = res.data
    })
}

function getPosts(page = 1, reset = false) {
  arePostsLoading.value = true

  axios.get(`/courses/communities/topics/${props.communityTopicId}/posts`, { params: { page } })
    .then((res) => {
      posts.value = reset ? res.data : { ...res.data, data: posts.value ? [...posts.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => arePostsLoading.value = false)
}

function createPost(payload: { content: string }) {
  isCreatingPost.value = true

  axios.post(`/courses/communities/topics/${props.communityTopicId}/posts`, payload)
    .then(() => {
      getPosts(1, true)
      postPayload.value = {
        content: ''
      }
    })
    .finally(() => {
      isCreatingPost.value = false
    })
}

onMounted(async () => {
  getTopic()
  getPosts()
  userAuthenticatedProfile.value = await getProfile()
})

return (_ctx: any,_cache: any) => {
  return (topic.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          class: "bg-[#d5d5d5] p-2 mb-4 rounded-full inline-flex"
        }, [
          _createVNode(_unref(MoveLeft), { color: "black" })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ProfileComponent, {
                hideDropdown: true,
                topic: { ...topic.value, author: { ...topic.value.author, avatar: profileAvatar.value } }
              }, null, 8, ["topic"])
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(topic.value.content), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(distinctReactions.value, (reaction, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "rounded-full bg-gray-500 w-5 h-5 flex items-center justify-center text-xs text-white"
                    }, _toDisplayString(reaction), 1))
                  }), 128))
                ]),
                (topic.value.reactions.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(topic.value.reactions.length), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(topic.value.postsCount) + " comentários", 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(TooltipProvider), null, {
              default: _withCtx(() => [
                _createVNode(_unref(Tooltip), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(["flex justify-center w-full", { 'cursor-not-allowed': !_unref(hasActiveParticipation) }])
                        }, [
                          _createVNode(Button, {
                            variant: "ghost",
                            class: _normalizeClass(["flex-1", { 
                  'text-blue-500 hover:text-blue-500': alreadyReacted.value?.content === _unref(CommunityTopicReactionContentEnum).LIKE,
                  'text-red-500 hover:text-red-500': alreadyReacted.value?.content === _unref(CommunityTopicReactionContentEnum).LOVE,
                }]),
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleReaction(_unref(CommunityTopicReactionContentEnum).LIKE))),
                            disabled: !_unref(hasActiveParticipation)
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(alreadyReacted.value ? _unref(communityTopicReactionIcons)[alreadyReacted.value.content] : _unref(ThumbsUpIcon)), {
                                "stroke-width": 1.25,
                                class: _normalizeClass(["h-5 w-5 mr-2 transition-colors duration-200", { 
                      'text-blue-500 fill-blue-500': alreadyReacted.value?.content === _unref(CommunityTopicReactionContentEnum).LIKE,
                      'text-red-500 fill-red-500': alreadyReacted.value?.content === _unref(CommunityTopicReactionContentEnum).LOVE,
                    }])
                              }, null, 8, ["class"])),
                              _createTextVNode(" " + _toDisplayString(alreadyReacted.value ? _unref(communityTopicReactionLabels)[alreadyReacted.value?.content] : 'Curtir'), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled", "class"])
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(TooltipContent), null, {
                      default: _withCtx(() => [
                        (_unref(hasActiveParticipation))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CommunityTopicReactionContentEnum), (reaction) => {
                                return (_openBlock(), _createElementBlock("button", {
                                  onClick: ($event: any) => (toggleReaction(reaction)),
                                  key: reaction,
                                  class: "cursor-pointer text-xl hover:scale-125 transition-transform duration-200"
                                }, _toDisplayString(reaction), 9, _hoisted_12))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_13, "Ação bloqueada! É necessário estar participando de um torneio."))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-lg font-semibold mb-4" }, "Comentários", -1)),
            (posts.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(posts.value.data, (post) => {
                    return (_openBlock(), _createBlock(CommunityPostComponent, {
                      key: post.id,
                      post: post
                    }, null, 8, ["post"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (posts.value?.pagination.nextPage)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: _normalizeClass(["pl-8 w-full text-center flex justify-center items-center", { 'text-zinc-400': arePostsLoading.value }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (getPosts(posts.value?.pagination.nextPage)))
                }, [
                  (arePostsLoading.value)
                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                        key: 0,
                        class: "w-4 h-4 mr-2 animate-spin"
                      }))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createTextVNode(" Ver mais respostas "))
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("form", _hoisted_17, [
                _createVNode(_unref(Avatar), null, {
                  default: _withCtx(() => [
                    (authProfileAvatar.value)
                      ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                          key: 0,
                          src: authProfileAvatar.value.bucketLocation,
                          alt: userAuthenticatedProfile.value!.name
                        }, null, 8, ["src", "alt"]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(AvatarFallback), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userAuthenticatedProfile.value?.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_unref(Textarea), {
                    modelValue: postPayload.value.content,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((postPayload.value.content) = $event)),
                    placeholder: "Escreva um comentário...",
                    rows: "2"
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_unref(TooltipProvider), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass({ 'cursor-not-allowed': !_unref(hasActiveParticipation) })
                                }, [
                                  _createVNode(Button, {
                                    type: "submit",
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (createPost(postPayload.value))),
                                    disabled: isCreatingPost.value || !postPayload.value.content || !_unref(hasActiveParticipation)
                                  }, {
                                    default: _withCtx(() => [
                                      (isCreatingPost.value)
                                        ? (_openBlock(), _createBlock(_unref(Loader2), {
                                            key: 0,
                                            class: "w-4 h-4 mr-2 animate-spin"
                                          }))
                                        : _createCommentVNode("", true),
                                      _cache[6] || (_cache[6] = _createTextVNode(" Comentar "))
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ], 2)
                              ]),
                              _: 1
                            }),
                            (!_unref(hasActiveParticipation))
                              ? (_openBlock(), _createBlock(_unref(TooltipContent), { key: 0 }, {
                                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                                    _createElementVNode("p", null, "Ação bloqueada! É necessário estar participando de um torneio.", -1)
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
}
}

})