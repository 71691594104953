<template>
  <div class="flex flex-col sm:gap-4 sm:py-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full">
      <Tabs default-value="all">
        <div class="flex items-center">
          <!-- <TabsList>
            <TabsTrigger value="all">
              Cursos
            </TabsTrigger>
          </TabsList> -->
          <div class="ml-auto flex items-center gap-2">
            <!-- <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="outline" size="sm" class="h-9 gap-1">
                  <ListFilter class="h-3.5 w-3.5" />
                  <span class="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filtro
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filtrar por</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem checked>
                  Ativo
                </DropdownMenuItem>
                <DropdownMenuItem>Inativo</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> -->
            <Button @click="$router.push({ name: 'courses.users.create', params: { courseId: courseId } })" size="sm" class="h-9 gap-1">
              <PlusCircle class="h-3.5 w-3.5" />
              <span class="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Adicionar Aluno
              </span>
            </Button>
          </div>
        </div>
        <TabsContent value="all">
          <Card>
            <CardHeader>
              <CardTitle class="text-[1.1rem]">Alunos</CardTitle>
              <CardDescription>
                Gerencie, crie e edite os seus alunos
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead class="hidden w-[100px] sm:table-cell">
                      <span class="sr-only">img</span>
                    </TableHead>
                    <TableHead>Nome</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Registrado em</TableHead>
                    <TableHead>
                      Ações
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody v-if="courseUsers">
                  <template v-if="courseUsers && !!courseUsers.data.length">
                    <TableRow v-for="user in courseUsers.data">
                      <TableCell class="hidden sm:table-cell">
                        <img
                          alt="Imagem do curso"
                          class="aspect-square rounded-md object-cover"
                          height="64"
                          :src="user.profile.media!.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)?.bucketLocation ?? '/assets/img/no-image.png'"
                          @error="($event.target as HTMLInputElement).src = '/assets/img/no-image.png'"
                          width="64"
                        >
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ user.profile.name }}
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ user.user.email }}
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ new Date(user.createdAt).toLocaleDateString() }}
                      </TableCell>
                      <TableCell>
                        <div class="flex gap-2">
                          <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
                          @click="isdeleteCourseUserUserConfirmationDialogOpen = true; selectedCourseUserIdToDelete = user.id">
                            <Trash2 class="w-5 h-5" />
                          </button>
                        </div>
                        <!-- <DropdownMenu>
                          <DropdownMenuTrigger as-child>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal class="h-4 w-4" :stroke-width="1.25" />
                              <span class="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Ações</DropdownMenuLabel>
                            <DropdownMenuItem @click="$router.push({ name: 'courses.edit', params: { courseId: course.id } })">Editar</DropdownMenuItem>
                            <DropdownMenuItem @click="isdeleteCourseUserUserConfirmationDialogOpen = true; selectedCourseUserIdToDelete = course.id">Excluir</DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu> -->
                      </TableCell>
                    </TableRow>
                  </template>
                  <template v-else class="relative">
                    <TableRow>
                      <TableCell colspan="5" class="text-center font-medium py-8">
                        Nenhum aluno encontrado.
                      </TableCell>
                    </TableRow>
                  </template>
                </TableBody>
              </Table>
              <SpinnerLoader v-if="!courseUsers" />
              <PaginationApi v-if="courseUsers" :data="courseUsers.pagination" :paginate="(page) => { courseUsers = undefined; getCourseUsers(page) }" />
            </CardContent>
            <CardFooter>
              <div v-if="courseUsers" class="text-xs text-muted-foreground">
                Exibindo <strong>1-{{ courseUsers.data.length }}</strong> de <strong>{{ courseUsers.pagination.total }}</strong>
                resultados
              </div>
            </CardFooter>
          </Card>
          <Dialog v-if="selectedCourseUserIdToDelete" :open="isdeleteCourseUserUserConfirmationDialogOpen" @update:open="(open) => isdeleteCourseUserUserConfirmationDialogOpen = open">
            <DialogContent class="sm:max-w-md">
              <DialogHeader>
                <div class="flex gap-2 items-center">
                  <AlertTriangle class="h-6 w-6" />
                  <DialogTitle class="text-[1.1rem]">Cuidado</DialogTitle>
                </div>
                <DialogDescription class="py-2">
                  Deseja mesmo deletar esse aluno?
                </DialogDescription>
              </DialogHeader>
              <div class="flex items-center space-x-2">
                
              </div>
              <DialogFooter class="sm:justify-start">
                <DialogClose as-child>
                  <Button type="button" variant="secondary">
                    Cancelar
                  </Button>
                </DialogClose>
                <Button type="button" :disabled="isDeletingCourseUser" @click="deleteCourseUser(selectedCourseUserIdToDelete)">
                  <Loader2 v-if="isDeletingCourseUser" class="w-4 h-4 mr-2 animate-spin" />
                  Confirmar
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </TabsContent>
      </Tabs>
      <Toaster />
    </main>
  </div>
</template>

<script setup lang="ts">
import {
  MoreHorizontal,
  PlusCircle,
  AlertTriangle,
  Loader2,
  Pencil,
  Trash2
} from 'lucide-vue-next'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  Tabs,
  TabsContent,
} from '@/components/ui/tabs'

import { onMounted, ref } from 'vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Pagination as IPagination }from '@/interfaces/Pagination'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { Dialog } from '@/components/ui/dialog'
import DialogContent from '@/components/ui/dialog/DialogContent.vue'
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue'
import DialogClose from '@/components/ui/dialog/DialogClose.vue'
import { toast, Toaster } from '@/components/ui/toast'
import { CourseUser } from '@/interfaces/courses/users/CourseUser'
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum'

const props = defineProps<{ courseId: number }>()
const axios = useAxios()
const courseUsers = ref<IPagination<CourseUser>>()
const isdeleteCourseUserUserConfirmationDialogOpen = ref(false)
const selectedCourseUserIdToDelete = ref<number>()
const isDeletingCourseUser = ref(false)

function getCourseUsers(page = 1) {
  axios.get(`/courses/${props.courseId}/users`, { params: { page } })
    .then((res) => {
      courseUsers.value = res.data
    })
}

function deleteCourseUser(id: number) {
  isDeletingCourseUser.value = true

  axios.delete(`/courses/${props.courseId}/users/${id}`)
    .then(() => {
      toast({
        title: 'Aluno excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      courseUsers.value!.data = courseUsers.value!.data.filter((course) => course.id !== id)
    })
    .catch(() => isDeletingCourseUser.value = false)
    .finally(() => {
      isDeletingCourseUser.value = false
      isdeleteCourseUserUserConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getCourseUsers()
})
</script>