<template>
  <div class="flex flex-col sm:gap-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 w-full max-w-[1700px] mx-auto">
      <div class="space-y-2">
        <h1 class="text-[1.1rem] font-bold tracking-tighter text-[#000] dark:text-white">Adicionar novo aluno</h1>
        <p class="text-sm text-[#666] dark:text-[#888]">Informe os dados do aluno</p>
      </div>
      <div class="mx-auto grid w-full flex-1 auto-rows-max gap-4">
        <Form
          v-slot="{ meta, values, validate }"
          as="" keep-values :validation-schema="toTypedSchema(formSchema)"
        >
          <form @submit.prevent="() => {
            validate();
            if (meta.valid) {
              createCourse(values as FormSchemaType)
            }
          }" class="grid gap-4 lg:gap-8 relative">
            <Card class="relative">
              <CardContent class="pt-6">
                <div class="grid gap-6 md:grid-cols-2">
                  <!-- Campo Nome -->
                  <FormField v-slot="{ componentField }" name="name">
                    <FormItem>
                      <FormLabel>Nome</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Nome completo"
                          v-bind="componentField"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <!-- Campo Email -->
                  <FormField v-slot="{ componentField }" name="email">
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          placeholder="ex: exemplo@dominio.com"
                          v-bind="componentField"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <!-- Campo Senha -->
                  <FormField v-slot="{ componentField }" name="password">
                    <FormItem>
                      <FormLabel>Senha</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="Mínimo 8 caracteres"
                          v-bind="componentField"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <!-- Campo Telefone -->
                  <FormField v-slot="{ componentField }" name="phoneNumber">
                    <FormItem>
                      <FormLabel>Número de Telefone</FormLabel>
                      <FormControl>
                        <Input
                          type="tel"
                          placeholder="(XX) X XXXX-XXXX"
                          v-bind="componentField"
                          v-maska="'(##) # ####-####'"
                          v-model="formValues.phoneNumber"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <!-- Campo Documento (CPF ou CNPJ) -->
                  <FormField v-slot="{ componentField }" name="document">
                    <FormItem>
                      <FormLabel>CPF ou CNPJ</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Informe CPF ou CNPJ"
                          v-bind="componentField"
                          v-model="formValues.document"
                          v-maska="formValues.document.length <= 14 ? '###.###.###-###' : '##.###.###/####-##'"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <!-- Botão de Enviar -->
                  <div class="md:col-span-2">
                    <Button :disabled="
                      (formValues.document && formValues.document.length !== 14 && formValues.document.length !== 18) ||
                      (!!formValues.phoneNumber && formValues.phoneNumber.length !== 16)
                    " type="submit" class="w-full md:w-auto">
                      <Loader2 v-if="isCreatingCourseUser" class="w-4 h-4 mr-2 animate-spin" />
                      Adicionar
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>
    </main>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { vMaska } from "maska/vue"
import { toTypedSchema } from '@vee-validate/zod'

import { Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import router from '@/router'

const formValues = ref({ phoneNumber: '', document: '' })
const props = defineProps<{ courseId: number }>()
const isCreatingCourseUser = ref(false)
const axios = useAxios()
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  name: z.string(requiredMessage),
  email: z.string(requiredMessage).email("Email inválido"),
  password: z.string(requiredMessage).min(8, "Senha deve ter no mínimo 8 caracteres"),
  phoneNumber: z.string().optional(),
  document: z.string().optional(),
});
type FormSchemaType = z.infer<typeof formSchema>

function createCourse(payload: FormSchemaType) {
  isCreatingCourseUser.value = true

  if (!payload.phoneNumber) delete payload.phoneNumber

  axios.post(`/courses/${props.courseId}/users`, payload)
    .then((res) => {
      toast({
        title: 'Aluno cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Esse email já foi cadastrado',
        description: 'Tente um outro email',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingCourseUser.value = false)
}
</script>