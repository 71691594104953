<template>
  <div class="flex space-x-3">
    <RouterLink :to="{ name: 'profile', params: { profileId: post.authorId } }">
      <Avatar>
        <AvatarImage v-if="profileAvatar" :src="profileAvatar.bucketLocation" :alt="post.author.name" />
        <AvatarFallback>{{ post.author.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
      </Avatar>
    </RouterLink>
    <div class="flex-grow">
      <div class="bg-zinc-800 rounded-2xl px-4 py-2">
        <RouterLink class="inline-flex gap-2" :to="{ name: 'profile', params: { profileId: post.authorId } }">
          <h4 class="font-semibold inline-flex">{{ post.author.name }}</h4>
          <Handshake
            v-if="post.author.isTrader" 
            class="text-green-500 w-5 h-5 relative" 
            title="Trader Verificado"
          />
        </RouterLink>
        <p class="">{{ post.content }}</p>
      </div>
      <div class="flex items-center space-x-4 mt-1 text-sm text-gray-500">
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger asChild>
              <div :class="{ 'cursor-not-allowed': !hasActiveParticipation }">
                <button :disabled="!hasActiveParticipation" @click="likePost(post.id, CommunityPostReactionContentEnum.LIKE)" 
                class="transition duration-200" 
                :class="{ 'text-blue-500': !!authUserReaction,'cursor-not-allowed': !hasActiveParticipation, 'hover:text-blue-500': hasActiveParticipation }">
                  Curtir
                </button>
              </div>
            </TooltipTrigger>
            <TooltipContent v-if="!hasActiveParticipation">
              <p>Ação bloqueada! É necessário estar participando de um torneio.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <button @click="toggleReply(post.id)" class="hover:text-blue-500 transition duration-200">
          Responder
        </button>
        <span>{{ timeAgo(post.createdAt) }}</span>
        <span v-if="post.reactions.length > 0" class="flex items-center">
          <ThumbsUpIcon class="w-4 h-4 text-blue-500 mr-1" />
          {{ post.reactions.length }}
        </span>
      </div>
      <button v-if="post.repliesCount && !replies?.data.length" class="mt-2 flex items-center" :class="{ 'text-zinc-400': isLoading }" @click="getPostReplies(post.id)">
        <Loader2 v-if="isLoading" class="w-4 h-4 mr-2 animate-spin" />
        <template v-if="post.repliesCount === 1">Ver {{ post.repliesCount }} resposta</template>
        <template v-else>Ver todas as {{ post.repliesCount }} respostas</template>
      </button>
      <div v-if="replyingTo === post.id" class="mt-2">
        <Textarea
          v-model="postReplyPayload.content"
          placeholder="Escreva uma resposta..."
          rows="2"
        />
        <div class="mt-2 flex justify-end space-x-2">
          <Button
            @click="cancelReply"
            variant="ghost"
          >
            Cancelar
          </Button>
          <TooltipProvider>
            <Tooltip :delay-duration="100">
              <TooltipTrigger asChild>
                <div :class="{ 'cursor-not-allowed': !hasActiveParticipation }">
                  <Button
                    :disabled="!postReplyPayload.content || isCreatingPostReply || !hasActiveParticipation"
                    @click="createPostReply({ ...postReplyPayload, parentId: post.id })"
                  >
                    <Loader2 v-if="isCreatingPostReply" class="w-4 h-4 mr-2 animate-spin" />
                    Responder
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent v-if="!hasActiveParticipation">
                <p>Ação bloqueada! É necessário estar participando de um torneio.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <!-- Nested Replies -->
      <div v-if="replies && post.repliesCount" class="mt-2 space-y-4">
        <div v-for="reply in replies.data" :key="reply.id" class="flex space-x-3 ml-8">
          <RouterLink :to="{ name: 'profile', params: { profileId: reply.authorId } }">
            <Avatar>
              <AvatarImage v-if="reply.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)" :src="reply.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)!.bucketLocation" :alt="reply.author.name" />
              <AvatarFallback>{{ reply.author.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
            </Avatar>
          </RouterLink>
          <div class="flex-grow">
            <div class="bg-zinc-800 rounded-2xl px-4 py-2">
              <RouterLink class="inline-flex gap-2" :to="{ name: 'profile', params: { profileId: reply.authorId } }">
                <h4 class="font-semibold">{{ reply.author.name }}</h4>
                <Handshake
                  v-if="reply.author.isTrader" 
                  class="text-green-500 w-5 h-5 relative" 
                  title="Trader Verificado"
                />
              </RouterLink>
              <p class="">{{ reply.content }}</p>
            </div>
            <div class="flex items-center space-x-4 mt-1 text-sm text-gray-500">
              <TooltipProvider>
                <Tooltip :delay-duration="100">
                  <TooltipTrigger asChild>
                    <div :class="{ 'cursor-not-allowed': !hasActiveParticipation }">
                      <button :disabled="!hasActiveParticipation" @click="likePost(reply.id, CommunityPostReactionContentEnum.LIKE)" class="transition duration-200" 
                      :class="{ 'text-blue-500': !!reply.reactions.length, 'cursor-not-allowed': !hasActiveParticipation, 'hover:text-blue-500': hasActiveParticipation }">
                        Curtir
                      </button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent v-if="!hasActiveParticipation">
                    <p>Ação bloqueada! É necessário estar participando de um torneio.</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <span>{{ timeAgo(reply.createdAt) }}</span>
              <span v-if="reply.reactions.length > 0" class="flex items-center">
                <ThumbsUpIcon class="w-4 h-4 text-blue-500 mr-1" />
                {{ reply.reactions.length }}
              </span>
            </div>
          </div>
        </div>
        <button class="pl-8 w-full text-center flex justify-center items-center" v-if="replies?.pagination?.nextPage" @click="getPostReplies(post.id)" :class="{ 'text-zinc-400': replies && isLoading }">
          <Loader2 v-if="replies && isLoading" class="w-4 h-4 mr-2 animate-spin" />
          Ver mais respostas
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AvatarImage } from '@/components/ui/avatar';
import Avatar from '@/components/ui/avatar/Avatar.vue';
import AvatarFallback from '@/components/ui/avatar/AvatarFallback.vue';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useAxios } from '@/composables/useAxios';
import { useDate } from '@/composables/useDate';
import { useTournament } from '@/composables/useTournament';
import { CommunityPostReactionContentEnum } from '@/enums/course/communities/communityPosts/communityPostReactions/CommunityPostReactionContentEnum';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { CommunityPost } from '@/interfaces/courses/communities/CommunityPost';
import { Pagination } from '@/interfaces/Pagination';
import { Handshake, Loader2, ThumbsUpIcon } from 'lucide-vue-next';
import { computed, ref } from 'vue';

const { hasActiveParticipation } = useTournament()
const props = defineProps<{ post: CommunityPost }>()
const axios = useAxios()
const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const replyingTo = ref<number | null>(null)
const replyContent = ref('')
const postReplyPayload = ref({
  content: ''
})
const isCreatingPostReply = ref(false)
const replies = ref<Pagination<CommunityPost>>()
const isLoading = ref(false)
const { timeAgo } = useDate()
const authUserReaction = computed(() => props.post.reactions.find((reaction) => reaction.userId === userAuthenticated.userId))
const profileAvatar = computed(() =>
  props.post.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);

const likePost = (id: number, content: CommunityPostReactionContentEnum) => {
  axios.post(`/courses/communities/topics/${props.post.communityTopicId}/posts/${id}/reactions`, { content })
  
  if (id === props.post.id) {
    const postAlreadyReacted = props.post.reactions.find((r) => r.userId === userAuthenticated.userId)
  
    if (!postAlreadyReacted) {
      props.post.reactions.push({
        content,
        userId: userAuthenticated.userId
      })
    } else {
      props.post.reactions = props.post.reactions.filter((r) => r.userId !== userAuthenticated.userId)
    }
  } else {
    const replyIndex = replies.value!.data.findIndex((r) => r.id === id)

    const postAlreadyReacted = replies.value!.data[replyIndex].reactions.find((r) => r.userId === userAuthenticated.userId)
    
    if (!postAlreadyReacted) {
      replies.value!.data[replyIndex].reactions.push({
        content,
        userId: userAuthenticated.userId
      })
    } else {
      replies.value!.data[replyIndex].reactions = replies.value!.data[replyIndex].reactions.filter((r) => r.userId !== userAuthenticated.userId)
    }
  }
}

const toggleReply = (id: number) => {
  replyingTo.value = replyingTo.value === id ? null : id
  replyContent.value = ''
}

const cancelReply = () => {
  replyingTo.value = null
  replyContent.value = ''
}

function getPostReplies(parentId: number, reset = false) {
  if (replies.value?.pagination.nextPage || !replies.value) {
    isLoading.value = true

    axios.get(`/courses/communities/topics/${props.post.communityTopicId}/posts`, { params: { parentId, page: replies.value?.pagination.nextPage ?? 1 } })
      .then((res) => {
        replies.value = reset ? res.data : { data: [...(replies.value?.data ?? []), ...res.data.data], pagination: res.data.pagination }
        isLoading.value = false
      })
  }
}

function createPostReply(payload: { content: string, parentId: number }) {
  isCreatingPostReply.value = true

  axios.post(`/courses/communities/topics/${props.post.communityTopicId}/posts`, payload)
    .then(() => {
      if (replies.value) {
        replies.value.pagination.nextPage = 1
      }

      getPostReplies(payload.parentId, true)
      props.post.repliesCount = (props.post.repliesCount ?? 0) + 1

      postReplyPayload.value = {
        content: ''
      }
    })
    .finally(() => {
      isCreatingPostReply.value = false
    })
}
</script>